import { Component, OnInit, Input, OnChanges, OnDestroy} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { imgBaseUrl } from '../parlamentare';

@Component({
  selector: 'app-grafiku',
  templateUrl: './grafiku.component.html',
  styleUrls: ['./grafiku.component.css','../pjesemarrja/pjesemarrja.component.css']
})
export class GrafikuComponent implements OnInit, OnChanges, OnDestroy {
  selectedTab: string = "";
  availableTabs: string[] = [
    "VOTAT_E_LIDEREVE_NE_DIAGRAM",
    "VOTAT_E_KANDIDATEVE_PER_KRYEMINISTER",
    "GARA_NDER_VITE_E_SUBJEKTEVE",
    "GARA_NDER_VITE_E_LIDEREVE"];
  tabsSrc: SafeResourceUrl[] = [];
  imgBaseUrl = '';

  constructor(private sanitizer: DomSanitizer) {
    this.imgBaseUrl = imgBaseUrl() + '/grafiku/';
    this.availableTabs.forEach(folder => {
      this.tabsSrc.push(
        this.sanitizer.bypassSecurityTrustResourceUrl(this.imgBaseUrl + folder + "/index.html")
      )
    });
  }

  ngOnInit() {
    // this.loadScript();
  }

  ngOnChanges() {}

  ngOnDestroy() {
    // this.removeScript();
  }

  tabClicked(tabName: any): void {
    this.selectedTab = tabName;
  }

  removeScript() {
    document.getElementById("flourish.embed.js").remove();
  }

  loadScript() {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '/grafiku/flourish.embed.js?cachebuster='+ new Date().getTime();
    script.id = 'flourish.embed.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
}
