<div class="row">
    <div class="col-sm-2"></div>
    <div class="col-sm-8">
        <div class="row displayContentCenter" >
            <div class="p-2" *ngFor="let year of electionsYears; let i = index">
                <button
                    type="button"
                    [ngClass]="selectedYear === year ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'"
                    (click)="loadData(year, -1); showMap = false;"
                >{{ year == 2019 ? '2019 Kurti' : year }}</button>
            </div>
            <div class="p-2">
                <button
                    type="button"
                    [ngClass]="selectedYear === 2020 ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'"
                    (click)="loadData(2020, -1); showMap = false;"
                >2019 Hoti</button>
            </div>
            <div class="p-2">
                <button
                    type="button"
                    [ngClass]="showMap ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'"
                    (click)="mapDeputies()"
                >HARTA MINISTRORE</button>
            </div>
        </div>
        <ng-template [ngIf]="!showMap">
            <div class="row displayContentCenter" [hidden]='personView'>
                <div style="width: 20%" *ngFor="let m of ministers; let i = index;">
                    <div 
                        id="{{'leader-data-' + i}}" 
                        class="content-center"
                        style="background-color: white; width: 100%; height: 330px; "
                        (click)='personClicked(m)'>
                        <div 
                            class="center" 
                            style="width: 100%; height: 80% !important; padding-top: 10px; border-right: 2px solid lightgrey;">
                            <div style="height:110px; width:110px; border-radius: 50%; background-color: green;
                                        margin: auto">
                                <img class="avatar" style="border-radius: 50%; margin: 5px 5px 5px 5px" [src]="imgBaseUrl + '/images/qeverite/' + m.profilePic" height="100" width="100" />
                            </div>
                            <h4 style="padding-top: 30px;">{{ m.name }}</h4>
                            <h5 style="color: lightslategrey">{{ m.party }}</h5>
                            <h6 style="color: lightslategrey">{{ m.position }}</h6>
                            <!-- <h6><a class="content-center" href="">Explore <i class="material-icons" style="font-size: 14px;">arrow_forward</i></a></h6> -->
                        </div>
                        
                        <!-- <h4>{{ person.votes | number }}</h4> -->
                    </div>
                    <div style="height: 10px;"></div>
                </div>
            </div>
        </ng-template>
        <ng-template [ngIf]="showMap && !showCityMinistres">
            <div class="row displayContentCenter" style="margin: 0 0 15px 0 !important;">
                <div class="center" >
                    <div
                        style="height: 40px; background-color:#D16517;" >
                        <h3 style="padding: 8px 5px 0 5px; color: white">SHPERNDARJA E MINISTRAVE NEPER KOMUNA</h3>
                    </div>
                </div>
            </div>
            <div class="row content-center">
                <app-kosovo-map 
                    [cityVotes]='cityVotes'
                    (cityClickHandeled)="cityClicked($event)" 
                    style="width: 65%" 
                    [clickMode]="2"
                    maxValue="7"
                    fillStatic="true">
                </app-kosovo-map>
            </div>
        </ng-template>
        <ng-template [ngIf]="showCityMinistres">
            <div class="row" style="padding-top: 10px">
                <div>
                  <button type="button"
                          class="btn btn-warning"
                          (click)="showCityMinistres = false; showMap = true">
                    <i class="material-icons" style="font-size: 14px;">arrow_back</i>
                    {{ selectedCityName }}
                  </button>
                </div>
                <!-- <div style="padding-left: 10px" *ngFor="let year of years; let i = index">
                    <button
                        type="button"
                        [ngClass]="selectedYear === year ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'"
                        (click)="loadData(year)"
                    >{{ year }}</button>
                </div> -->
            </div>
            <div class="row" [hidden]='personView'>
                <div class="col-sm-8">
                    <div class="row displayContentCenter">
                        <div style="width: 25%" *ngFor="let m of ministers; let i = index;">
                            <div 
                                id="{{'leader-data-' + i}}" 
                                class="content-center"
                                style="background-color: white; width: 100%; height: 300px; "
                                (click)='personClicked(m)'>
                                <div 
                                    class="center" 
                                    style="width: 100%; height: 80% !important; padding-top: 10px; border-right: 2px solid lightgrey;">
                                    <div style="height:110px; width:110px; border-radius: 50%; background-color: green;
                                                margin: auto">
                                        <img class="avatar" style="border-radius: 50%; margin: 5px 5px 5px 5px" [src]="imgBaseUrl + '/images/qeverite/' + m.profilePic" height="100" width="100" />
                                    </div>
                                    <h4 style="padding-top: 30px;">{{ m.name }}</h4>
                                    <h5 style="color: lightslategrey">{{ m.party }}</h5>
                                    <h6 style="color: lightslategrey">{{ m.position }}</h6>
                                    <!-- <h6><a class="content-center" href="">Explore <i class="material-icons" style="font-size: 14px;">arrow_forward</i></a></h6> -->
                                </div>
                                
                                <!-- <h4>{{ person.votes | number }}</h4> -->
                            </div>
                            <div style="height: 10px;"></div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div style="padding-top: 50px">
                        <app-all-cities-map 
                            [cityId]="selectedCityId">
                        </app-all-cities-map>
                    </div>
                </div>
            </div>
        </ng-template>
        <div class="row" style="height: 400px" *ngIf="personView">
          <div class="row" style="background-color: white; width: 100%; min-height: 500px">
            <div class="col-sm-4" style="text-align: center">
              <h2 style="padding-top: 10px;">{{ selectedPerson.name }}</h2>
              <br>
              <img class="avatar" style="width: 100%; height: auto;" [src]="imgBaseUrl + '/images/qeverite/'+selectedPerson.profilePic" />
            </div>
            <div class="col-sm-8" style="padding-left: 40px">
              <div class="row" style="width: 100%; height: 80px;">
                <div class="p-2 close-modal-div" (click)="showTableModal = false;"
                     style="border-radius: 50% !important; margin-left: auto; margin-right: 20px; margin-top: 20px; cursor: pointer;"
                     (click)="personView = false;">
                  <i class="material-icons"> close</i>
                </div>
              </div>
              <div class="row" style="width: 80%">
                <h5 style="margin-top: 10px; white-space: pre-line;">{{ selectedPerson.biography }}</h5>
              </div>
              <div class="row" style="margin-top: 100px">
                <div>
                  <hr>
                  <h4><i class="material-icons" style="font-size: 14px;">flag</i>  {{ selectedPerson.party }}</h4>
                  <h4><i class="material-icons" style="font-size: 14px;">business</i>  {{ selectedPerson.cityOfBirth }}</h4>
                  <!-- <h4><i class="material-icons" style="font-size: 14px;">call</i>  {{ selectedPerson.cityOfBirth }}</h4> -->
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>
