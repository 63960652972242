export const options = {
    title: "",
    titlePosition: '',
    titleTextStyle: {
      color: "#FFFFFF",
      fontSize: 15,
      bold: true
    },
    pieHole: 0.87,
    width:250,
    height:250,
    backgroundColor: "",
    colors: ["", ""],
    pieSliceBorderColor : "",
    pieSliceText: "",
    pieSliceTextStyle: {
      bold: true
    },
    legendTextStyle: {
      color: "#FFFFFF",
      fontSize: 13,
      bold: false
    },
    legend: {
      alignment: 'center',
      position: 'bottom'            
    }

};

export class PersonsWithRightToVote {
  id: number;
  nrOfPersons: number;
  year: number;
}

