<div class="row">
    <div class="col-sm-1"></div>
    <div class="col-sm-10">
        <div class="d-flex flex-row displayContentCenter">
            <div class="p-2" *ngFor="let year of coalitions">
                <button type="button" [ngClass]="selectedYear === year ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'" (click)="loadData(year)">{{year}}</button>
            </div>
        </div>

        <div class="d-flex flex-row displayContentCenter">
            <div class="row" style="min-height: 500px;">
                <div class="col-sm-1"></div>
                <div class="col-sm-10 align-self-center" style="min-width: 500px;" 
                    *ngFor="let coalition of coalitions" [ngStyle]="{'display': selectedYear === coalition ? 'block' : 'none'}">
                    <img src="{{ imgBaseUrl + '/koalicione/' + coalition + '.svg' }}" style="width: 100%;"/>
                </div>
            </div>
        </div>

        <!-- <div class="f-flex flex-row displayContentCenter">
            <div class="row displayContentCenter" >
                <ng-template ngFor let-partie [ngForOf]="createRange(parties.length)" let-in="index">
                    <div class="col-sm-1 p-2 align-self-center" >
                        <img class="white-background" style="width: 100%; max-width: 100px; border-radius: 5%;" 
                            src="{{ imgBaseUrl + '/images/' + parties[in].photo }}"/>
                    </div>
                    <div class="col-sm-1 p-2 align-self-center" style="text-align: center;" *ngIf="in < (parties.length-1)">
                        <img style="width: 100%; max-width: 60px;" [src]="(in < parties.length-1) ? (imgBaseUrl + '/images/PLUS_DARK.png') : ''"/><!--(imgBaseUrl + '/images/EQUALS_DARK.png')"/> -->
                    <!-- </div> -->
                    <!-- <div class="col-sm align-self-center">
                        <div class="row" >
                            
                        </div>
                    </div> -->
                <!-- </ng-template>
            </div>
        </div> -->

        <!-- <div class="d-flex flex-row displayContentCenter">
            <div class="row" style="min-height: 500px;">
                <div class="col-sm-6 align-self-center" style="min-width: 500px;">
                    <div class="p-2 align-self-center" style="height: 100%; width: 100%;"> -->
                        <!-- <app-uleset-ne-parlament [year]="yearToLoad" [parties]="parties" ></app-uleset-ne-parlament> -->
                        <!-- <div class="d-flex flex-row displayContentCenter">
                            <div class="flourish-embed" data-src="/koalicionet/index.html">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>