import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { LocaleService } from '../../locale.service';
import { imgBaseUrl } from '../../../parlamentare/parlamentare';

declare let google: any;

@Component({
  selector: 'app-historiku-komuna',
  templateUrl: './komuna.component.html',
  styleUrls: ['./komuna.component.css','../historiku.component.css']
})
export class HistorikuKomunaComponent implements OnInit, OnChanges {
  @Input() selectedCityView: string = "Parlamentare";
  @Input() availableViews: string[] = [];
  @Input() loadYear: number = 2021;
  @Input() selectedCity: number = -1;

  selectedYear: number = this.loadYear;
  showMap = false;

  candidates: any[] = [];
  twoCandidates: any[] = [];
  parlament: any[] = [];
  assembly: any[] = [];
  candidatesSVGUrl: SafeResourceUrl = "";

  partiesNumberOfVotes: any[] = [];
  parlimentResultsByPollingStation: any[] = [];
  parlamentSVGUrl: SafeResourceUrl = "";

  assemblyResultsByPollingStation: any[] = [];
  assemblySVGUrl: SafeResourceUrl = "";

  mostVotedParties: any[] = [];
  mostMunicipalitiesCount: any[] = [];

  showPartyVotes = false;
  selectedCityId: number = this.selectedCity;

  showTableModal = false;
  tableModalPartyVotes: any[] = [];
  tableModalSelectedYear: number;

  selectedCityName: string;
  winingParties: any[] = [];

  imgBaseUrl = "";

  constructor(private service: LocaleService, private _sanitizer: DomSanitizer) {
    this.imgBaseUrl = imgBaseUrl();
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnChanges() {
    if (this.selectedCityId !== this.selectedCity) {
      this.selectedCityId = this.selectedCity;
    }
    if (this.loadYear !== this.selectedYear) {
      this.loadData();
    }
  }

  loadData(): void {
    this.showMap = false;
    this.selectedYear = this.loadYear;
    if (this.selectedCity === -1 || this.selectedCityId === -1) return;
    if (this.availableViews.includes("Parlamentare")) {
      this.getParliamentResultsByCity();
    }
    if (this.selectedYear === 2021) {
      this.candidates = [];
      this.twoCandidates = [];
      this.candidatesSVGUrl = "";
      this.assemblyResultsByPollingStation = [];
      this.assemblySVGUrl = "";
      return;
    }
    if (this.availableViews.includes("Kryetar")) {
      this.getResultsLocalForChairman();
    }
    if (this.availableViews.includes("Asamble")) {
      this.getResultsLocalForAssembly();
    } 
  }

  getParliamentResultsByCity() {
    this.service.getParliamentResultsByCity(this.selectedCityId, this.selectedYear).subscribe(res => {
      if (res["partiesNumberOfVotes"]) {
        const sorted = res["partiesNumberOfVotes"].sort((a, b) => b.nrOfVotes - a.nrOfVotes);
        let sumOfVotes = 0;
  
        sorted.forEach(p => sumOfVotes += p.nrOfVotes);
        const normalizePercentage = 75 / ((sorted[0].nrOfVotes/sumOfVotes) * 75);
        //75 is the max value for the graph.
  
        this.partiesNumberOfVotes = sorted.map(p => ({
          ...p,
          nrOfVotes: p.nrOfVotes.toLocaleString(),
          percentage: (((p.nrOfVotes/sumOfVotes) * 75) * normalizePercentage) + "%"
        }));
      }
      if (res["parlimentResultsByPollingStation"]) {
        this.parlimentResultsByPollingStation = res["parlimentResultsByPollingStation"];
      }
      if (res["svgFile"]) {
        this.parlamentSVGUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`/images/lokale/${this.selectedCityId}/${res["svgFile"]}`);
      }
    })
  }

  getResultsLocalForChairman() {
    this.service.getResultsLocalForChairman(this.selectedCityId, this.selectedYear).subscribe(res => {
      if (res["candidates"]) {
        this.candidates = res["candidates"]
          .sort((a, b) => b.nrVotes - a.nrVotes)
          .map(c => ({
            ...c,
            nrVotes: c.nrVotes.toLocaleString(),
            candidate: c.candidate.replace(" ","\n")
          }));
        this.twoCandidates = [];
        this.twoCandidates.push(this.candidates[0]);
        this.twoCandidates.push(this.candidates[1]);
      }

      if (res["svgFile"]) {
        this.candidatesSVGUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`/images/lokale/${this.selectedCityId}/${res["svgFile"]}`);
      }
    })
  }

  getResultsLocalForAssembly() {
    this.service.getResultsLocalForAssembly(this.selectedCityId, this.selectedYear).subscribe(res => {
      if (res["assemblyResultsByPollingStation"]) {
        this.assemblyResultsByPollingStation = res["assemblyResultsByPollingStation"];
      }

      if (res["svgFile"]) {
        this.assemblySVGUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`/images/lokale/${this.selectedCityId}/${res["svgFile"]}`);
      }
    })
  }

  nrOfCircles(nr: any, half: boolean = false, halfWithMod: boolean = false): number[] {
    let arr: number[] = [];
    let to: number = parseInt(nr);
    if (half && halfWithMod) {
      to = Math.floor((to / 2) + (to % 2));
    } else if (half) {
      to = Math.floor(to / 2);
    }
    for (let i=0; i<to; i++) {
      arr.push(i);
    }
    return arr;
  }
}
