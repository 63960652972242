import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getBaseUrl } from 'src/main';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  baseUrl: string;

  constructor(private http: HttpClient) { 
    this.baseUrl = getBaseUrl();
  }

  getParliamentResultsByCity(cityId: number, year: number) {
    return this.http.get(this.baseUrl + `api/LocalElections/GetParliamentResultsByCity/city/${cityId}/year/${year}`);
  }

  getResultsLocalForChairman(cityId: number, year: number) {
    return this.http.get(this.baseUrl + `api/LocalElections/GetResultsLocalForChairman/city/${cityId}/year/${year}`);
  }

  getResultsLocalForAssembly(cityId: number, year: number) {
    return this.http.get(this.baseUrl + `api/LocalElections/GetResultsLocalForAssembly/city/${cityId}/year/${year}`);
  }

  getLocaleResultsForCities(year: number) {
    return this.http.get(this.baseUrl + `api/LocalElections/GetLocaleResultsForCities/year/${year}`);
  }
}
