
export const cities = [
    { name: 'Prishtina', id: 5 },
    { name: 'Prizren', id: 1 },
    { name: 'Ferizaj', id: 12 },
    { name: 'Pejë ', id: 2 },
    { name: 'Gjakovë', id: 3 },
    { name: 'Gjilan', id: 9 },
    { name: 'Mitrovicë', id: 15 },
    { name: 'Podujeva', id: 0 },
    { name: 'Vushtrri', id: 13 },
    { name: 'Suharekë', id: 11 },
    { name: 'Rahovec', id: 21 },
    { name: 'Drenas', id: 5 },
    { name: 'Lipjan', id: 16 },
    { name: 'Malishevë', id: 18 },
    { name: 'Kamenicë', id: 8 },
    { name: 'Viti', id: 22 },
    { name: 'Deçan', id: 19 },
    { name: 'Istog', id: 6 },
    { name: 'Klinë', id: 17 },
    { name: 'Skenderaj', id: 10 },
    { name: 'Dragash', id: 7 },
    { name: 'Fushë Kosovë', id: 31 },
    { name: 'Kaçanik', id: 24 },
    { name: 'Shtime', id: 26 },
    { name: 'Obiliq', id: 29 },
    { name: 'Leposaviq', id: 4 },
    { name: 'Graçanicë', id: 28 },
    { name: 'Han i Elezit', id: 30 },
    { name: 'Zveçan', id: 27 },
    { name: 'Shtërpcë', id: 23 },
    { name: 'Novobërdë', id: 25 },
    { name: 'Zubin Potok', id: 14 },
    { name: 'Junik', id: 33 },
    { name: 'Mamusha', id: 36 },
    { name: 'Ranillug', id: 32 },
    { name: 'Kllokoti', id: 35 },
    { name: 'Parteshi', id: 34 },
    { name: 'Gllogovc', id: 20 }
];
