import { Component, OnInit, Input } from '@angular/core';
import { ParalamentareService } from '../../paralamentare.service';

declare let google: any;

@Component({
  selector: 'app-lideret',
  templateUrl: './lideret.component.html'
})
export class LideretComponent implements OnInit {

  @Input() leaderId: number;

  years = [2007, 2010, 2014, 2017];
  leaderVotesByYear: any[] = [];
  profilePic: String;
  leaderName: String;
  cityVotes: any[] = [];
  maxValue = 0;

  constructor(
    private _paralamentareService: ParalamentareService) {

    google.charts.load('current', { 'packages': ['corechart', 'table'] });
    google.charts.setOnLoadCallback();
  }

  ngOnInit() {

    this._paralamentareService.getLeaderData(this.leaderId).subscribe(response => {
      const res = response as any;

      this.leaderVotesByYear = res.leaderVotesByYear;

      this.profilePic = '/Images/' + res.leader.profilePic;
      this.leaderName = res.leader.name;

      let cityVotes = [];
      res.leaderVotesByCity.forEach(i => {
        const a = {
          votes: '' + i.votes,
          name: '',
          id: i.idCity
        };
        cityVotes.push(a);
      });

      this.cityVotes = cityVotes;
      this.maxValue = this.findMaxVotes();

      this.drawMostVotedLeader(this.leaderVotesByYear);
    });

  }

  findMaxVotes() {
    let max = 0;

    this.cityVotes.forEach(e => {
      const el = parseInt(e.votes, 10);
      if (el > max) {
        max = el;
      }
    });

    return max;
  }

  drawMostVotedLeader(obj: any): void {

    const styles = ['#b87333', 'silver', 'gold', '#e5e4e2'];

    let data = new google.visualization.DataTable();

    data.addColumn('string', 'Vitet');
    data.addColumn('number', 'Numri i votave');
    data.addColumn({ type: 'string', role: 'style' });
    data.addColumn({ type: 'string', role: 'annotation' });

    this.leaderVotesByYear.sort(( a, b ) => {
      if ( a.year < b.year ){
        return 1;
      }
      if ( a.year > b.year ){
        return -1;
      }
      return 0;
    });

    this.leaderVotesByYear.forEach(leader => {
      data.addRow(['' + leader.year, leader.votes, 'silver', '' + leader.votes]);
    });

    const options = {
      legend: 'none',
      backgroundColor: {
        fill: 'transparent'
      },
      //width: 300,
      height: 350,
      chartArea: {
        height: '80%',
        width: '75%'
      },
      hAxis: {
        minValue: 0,
        textStyle:{color: '#7D7D7D', bold: true, fontSize: 20}
      },
      vAxis: {
        textStyle:{color: '#7D7D7D', bold: true, fontSize: 20}
      },
      animation: {
        startup: true,
        duration: 1500,
        easing: 'out',
      },
      annotations: {
        textStyle: {
            fontSize: 20,
            color: '#7D7D7D',
            bold: true
        }
      },
    };

    const chart = new google.visualization.BarChart(document.getElementById('leader-chart'));

    chart.draw(data, options);
  }
}
