<div class="d-flex flex-row header">
    <div class="col-sm-3">
        <div id="vlogo" (click)="backToMainView.emit()">
            <div id="vlogo-color">
                <object height="60vh" data="/icons/vlogo_icon.svg"></object>
            </div>
            <div id="vlogo-grey">
                <object height="60vh" data="/icons/vlogo_icon_grey.svg"></object>
            </div>
        </div>
        <div class="p-2 titleText">
            HISTORIKU I<br />ZGJEDHJEVE
        </div>
    </div>
    <div class="col-sm-5">
        <div class="row">
            <h5 class="btn-label-historiku">PARASHIKUESI: </h5>
            <input type="text" 
                class="btn btn-historiku"
                [(ngModel)]="analystName" 
                [disabled]="!newAnalysis"
                placeholder="Emri"/>
            <button type="button" class="btn btn-save-historiku"
                (click)="analysisAction()"
                > {{ newAnalysis ? "RUAJ" : "PARASHIKIM I RI" }} </button>
        </div>
    </div>
    <div class="col-sm-4">
        <h5 class="btn-label-historiku">ANALIZAT E KALUARA: </h5>
        <select class="btn dropdown-historiku" style="background-color: white; width: 50%;"
                [(ngModel)]="oldAnalysis" 
                (change)="showOldAnalyse()">
            <option [value]="-1"></option>
            <option *ngFor="let o of mapAnalyses;" [value]="o.id">{{ o.name }}</option>
        </select>
    </div>
</div>

<div class="d-flex flex-row lokale-full-screen">
    <div class="col-sm-5 subjektet-fituese-ngjyrat">
        <div *ngFor="let party of partyList; let i = index" class="subjekti" (click)="selectParty(i)" 
            [ngStyle]="{'background-color': selectedPartyId === i ? party.color : 'transparent'}">
            <div class="ngjyra" 
                [ngStyle]="{'background-color': selectedPartyId === i ? 'white' : party.color}"></div>
            <div class="ngjyra-emri" 
                [ngStyle]="{'color': selectedPartyId === i ? 'white' : 'black'}">{{party.name}}</div>
        </div>
    </div>
    <div class="col-sm-5 center-items">
    <div style="width: 100%">
        <!-- <app-kosovo-map (cityClickHandeled)="cityClicked($event)" [winingParties]="winingParties" clickMode='-1'></app-kosovo-map> -->
        <app-kosovo-map 
            *ngIf="!showMapAnalysis"
            [cityFillColor]="partyList[selectedPartyId] ? partyList[selectedPartyId].color : ''"
            [clickMode]="2"
            (cityClickHandeled)='cityClicked($event)'>
        </app-kosovo-map>

        <app-kosovo-map 
            *ngIf="showMapAnalysis"
            [cityFillByAnalist]='partyList' 
            (cityClickHandeled)="cityClicked($event)" 
            [clickMode]="2" 
            [clickable]="false">
        </app-kosovo-map>
    </div>
    </div>
</div>