import { Component, OnInit } from '@angular/core';
import { ParalamentareService } from '../paralamentare.service';
import { GjeografiaZgjedhoreService } from '../gjeografia-zgjedhore/gjeografia-zgjedhore.service';

declare let google: any;

@Component({
  selector: 'app-krahaso',
  templateUrl: './krahaso.component.html',
  styleUrls: ['../pjesemarrja/pjesemarrja.component.css']

})
export class KrahasoComponent implements OnInit {

  // resultFromDB = [
  //   { name: 'PDK', votes: 43212, color: 'blue', city: 'Prishtina', year: 2017 },
  //   { name: 'LDK', votes: 32010, color: 'red', city: 'Prishtina', year: 2017 },
  //   { name: 'AAK', votes: 10320, color: 'yellow', city: 'Prishtina', year: 2017 },
  //   { name: 'VV', votes: 26788, color: 'pink', city: 'Prishtina', year: 2017 },
  //   { name: 'NISMA', votes: 4000, color: 'orange', city: 'Prishtina', year: 2017 },
  //   { name: 'PARTIA E FORTE', votes: 3821, color: 'black', city: 'Prishtina', year: 2017 },
  //   { name: 'PDK', votes: 37808, color: 'red', city: 'Prishtina', year: 2014 },
  // ];

  partiesList: any[] = [];

  compareType = 2;

  parties = ['PDK', 'LDK', 'AAK', 'VV', 'NISMA', 'PARTIA E FORTE'];
  // years = [2001, 2004, 2007, 2010, 2014, 2017, 2019];
  years = [2001, 2004, 2007, 2010, 2014, 2017,2019];
  cities = [
    { name: 'Prishtina', id: 5 }, { name: 'Prizren', id: 1 }, { name: 'Ferizaj', id: 12 }, { name: 'Pejë ', id: 2 }, { name: 'Gjakovë', id: 3 }, { name: 'Gjilan', id: 9 }, { name: 'Mitrovicë', id: 15 },
    { name: 'Podujeva', id: 0 }, { name: 'Vushtrri', id: 13 }, { name: 'Suharekë', id: 11 }, { name: 'Rahovec', id: 21 }, { name: 'Drenas', id: 5 }, { name: 'Lipjan', id: 16 }, { name: 'Malishevë', id: 18 },
    { name: 'Kamenicë', id: 8 }, { name: 'Viti', id: 22 }, { name: 'Deçan', id: 19 }, { name: 'Istog', id: 6 }, { name: 'Klinë', id: 17 }, { name: 'Skenderaj', id: 10 }, { name: 'Dragash', id: 7 },
    { name: 'Fushë Kosovë', id: 31 }, { name: 'Kaçanik', id: 24 }, { name: 'Shtime', id: 26 }, { name: 'Obiliq', id: 29 }, { name: 'Leposaviq', id: 4 }, { name: 'Graçanicë', id: 28 },
    { name: 'Han i Elezit', id: 30 }, { name: 'Zveçan', id: 27 }, { name: 'Shtërpcë', id: 23 }, { name: 'Novobërdë', id: 25 }, { name: 'Zubin Potok', id: 14 }, { name: 'Junik', id: 33 },
    { name: 'Mamusha', id: 36 }, { name: 'Ranillug', id: 32 }, { name: 'Kllokoti', id: 35 }, { name: 'Parteshi', id: 34 }, { name: 'Gllogovc', id: 20 }
  ];

  selectedParty = [0, 1];
  selectedYear = this.years[0];
  selectedYear1 = this.years[1];
  selectedCity = this.cities[0].id;

  selectedParty1: any;
  selectedParty2: any;

  isComparing = false;

  partyCompareList: any[] = [];

  partiesTotalVotes: any[] = [];

  selectedLeader1: string;
  selectedLeader2: string;

  leadersList1: any[] = [];
  leadersList2: any[] = [];

  constructor(private _paralamentareService: ParalamentareService,
              private _gjeografiaZgjedhoreService: GjeografiaZgjedhoreService) {
    google.charts.load('current', { 'packages': ['corechart', 'table'] });
    google.charts.setOnLoadCallback();
  }

  ngOnInit() {
    if (this.compareType === 3) {
      this.selectedYear = this.years[this.years.length-1];
      this.selectedYear1 = this.years[this.years.length-1];
      this.getLeadersToCompare(true, true);
    } else {
      this.getPartiesToCompare();
    }
  }

  setCompareType(compareType: number): void {
    this.compareType = compareType;

    // check this
    if (this.compareType === 3) {
      this.selectedYear = this.years[this.years.length-1];
      this.selectedYear1 = this.years[this.years.length-1];
      this.getLeadersToCompare(true, true);
    } else {
      this.getPartiesToCompare();
    }
  }

  getPartiesToCompare(): void {
    this.partiesList = [];
    if (this.selectedCity == 99 && this.compareType == 2) {
      this._gjeografiaZgjedhoreService.getMostVotedPartiesElectiveGeo(this.selectedYear).subscribe(response => {
        const res = response as any;
        res.forEach(p => {
          this.partiesList.push({
            color: p.color,
            id: p.id,
            idCitySvg: 99,
            partyName: p.name,
            partyVotes: p.nrOfVotes,
            year: p.year
          })
        });
        this.selectedParty1 = this.partiesList[0].id;
        this.selectedParty2 = this.partiesList[1].id;
      });
    } else if (this.selectedCity == 99 && this.compareType == 1) {
      this._gjeografiaZgjedhoreService.getPartyTotalVotesInYears().subscribe(response => {
        const res = response as any;
        res.forEach(p => {
          this.partiesTotalVotes.push({
            color: p.color,
            id: p.id,
            idCitySvg: 99,
            partyName: p.name,
            partyVotes: p.nrOfVotes,
            year: p.year
          });
          const index = this.partiesList.findIndex(el => el.partyName === p.name);
          if(index == -1) {
            this.partiesList.push({
              color: p.color,
              id: p.id,
              idCitySvg: 99,
              partyName: p.name,
              partyVotes: p.nrOfVotes,
              year: p.year
            });
          }
        });
        this.selectedParty1 = this.partiesList[0].id;
      });
    } else {
      this._paralamentareService.getPartiesToCompare(this.selectedYear, this.selectedCity).subscribe(response => {
        const res = response as any;
        this.partiesList = res;
        this.selectedParty1 = this.partiesList[0].id;
        this.selectedParty2 = this.partiesList[1].id;
      });
    }
  }

  getLeadersToCompare(calledForList1 = true, calledFromInit = false): void {
    const year = calledForList1 ? this.selectedYear : this.selectedYear1;

    this._paralamentareService.getLeadersToCompare(year).subscribe(response => {
      const res = response as any;
      if(calledFromInit) {
        this.leadersList1 = res;
        this.leadersList2 = res;
      } else if(calledForList1) {
        this.leadersList1 = res;
      } else {
        this.leadersList2 = res;
      }
    });
  }

  getPartiesByYearList(): void {
    const party = this.partiesList.filter(i => i.id === parseInt(this.selectedParty1, 10))[0];
    this._paralamentareService
      .getPartyDataByYears(party.partyName, this.selectedYear, this.selectedYear1, this.selectedCity).subscribe(response => {
        const res = response as any;
        this.partyCompareList = res;
        this.drawTotalVotes();
        this.drawCompareVotes();
      });
  }

  compareNow(): void {
    if (this.compareType === 1) {
      this.getPartiesByYearList();
    } else if (this.compareType === 3){
      this.drawLeadersVotes();
      this.drawLeadersCompareVotes();
    } else {
      this.drawTotalVotes();
      this.drawCompareVotes();
    }
  }

  drawLeadersVotes(): void {
    const p1 = this.leadersList1.filter(l => l.id === parseInt(this.selectedLeader1, 10))[0];
    const p2 = this.leadersList2.filter(l => l.id === parseInt(this.selectedLeader2, 10))[0];

    let data = new google.visualization.DataTable();

    data.addColumn('string', 'Lideri');
    data.addColumn('number', 'Numri i votave');
    data.addColumn({ type: 'string', role: 'style' });
    data.addColumn({ type: 'string', role: 'annotation' });

    data.addRow([p1.name+' '+p1.year, p1.votes, 'lightblue', '' + p1.votes]);
    data.addRow([p2.name+' '+p2.year, p2.votes, 'lightblue', '' + p2.votes]);
    // data.addRow([n2, p2.votes, p2.color, '' + p2.votes]);

    const options = {
      legend: 'none',
      backgroundColor: {
        fill: 'transparent'
      },
      // width: 300,
      height: 300,
      chartArea: {
        height: '70%',
        width: '70%'
      },
      hAxis: {
        viewWindowMode: 'explicit',
        viewWindow: {
          max: (p1.partyVotes > p2.partyVotes ? p1.partyVotes * 1.1 : p2.partyVotes * 1.1),
          min: 0
        },
        textStyle:{color: '#7D7D7D', bold: false, fontSize: 20}
      },
      vAxis: {
        textStyle:{color: '#7D7D7D', bold: false, fontSize: 20}
      },
      animation: {
        startup: true,
        duration: 1500,
        easing: 'out',
      },
      annotations: {
        // alwaysOutside: true,
        textStyle: {
            fontSize: 20,
            color: '#7D7D7D',
            bold: true
        }
      },
    };

    const chart = new google.visualization.BarChart(document.getElementById('total-votes'));

    chart.draw(data, options);
  }

  drawTotalVotes(): void {
    let p1: any;
    let p2: any;
    let n1: String;
    let n2: String;

    if (this.compareType === 2) {
      p1 = this.partiesList.filter(p => p.id === parseInt(this.selectedParty1, 10))[0];
      p2 = this.partiesList.filter(p => p.id === parseInt(this.selectedParty2, 10))[0];
    } else if(this.compareType == 1 && this.selectedCity == 99) {
      const partyName = this.partiesList.filter(el => el.id === parseInt(this.selectedParty1, 10))[0].partyName;
      p1 = this.partiesTotalVotes.filter(p => p.partyName == partyName && p.year == this.selectedYear)[0];
      p2 = this.partiesTotalVotes.filter(p => p.partyName == partyName && p.year == this.selectedYear1)[0];
    } else {
      p1 = this.partyCompareList[0];
      p2 = this.partyCompareList[1];
    }


    let data = new google.visualization.DataTable();

    data.addColumn('string', 'Partia');
    data.addColumn('number', 'Numri i votave');
    data.addColumn({ type: 'string', role: 'style' });
    data.addColumn({ type: 'string', role: 'annotation' });

    data.addRow([(this.compareType == 2 ? p1.partyName : ''+p1.year), p1.partyVotes, p1.color, '' + p1.partyVotes]);
    data.addRow([(this.compareType == 2 ? p2.partyName : ''+p2.year), p2.partyVotes, p2.color, '' + p2.partyVotes]);
    // data.addRow([n2, p2.votes, p2.color, '' + p2.votes]);

    const options = {
      legend: 'none',
      backgroundColor: {
        fill: 'transparent'
      },
      // width: 300,
      height: 300,
      chartArea: {
        height: '70%',
        width: '70%'
      },
      hAxis: {
        viewWindowMode: 'explicit',
        viewWindow: {
          max: (p1.partyVotes > p2.partyVotes ? p1.partyVotes * 1.1 : p2.partyVotes * 1.1),
          min: 0
        },
        textStyle:{color: '#7D7D7D', bold: false, fontSize: 20}
      },
      vAxis: {
        textStyle:{color: '#7D7D7D', bold: false, fontSize: 20}
      },
      animation: {
        startup: true,
        duration: 1500,
        easing: 'out',
      },
      annotations: {
        // alwaysOutside: true,
        textStyle: {
            fontSize: 20,
            color: '#7D7D7D',
            bold: true
        }
      },
    };

    const chart = new google.visualization.BarChart(document.getElementById('total-votes'));

    chart.draw(data, options);
  }

  drawLeadersCompareVotes(): void {
    const p1 = this.leadersList1.filter(l => l.id === parseInt(this.selectedLeader1, 10))[0];
    const p2 = this.leadersList2.filter(l => l.id === parseInt(this.selectedLeader2, 10))[0];

    let data = new google.visualization.DataTable();

    data.addColumn('string', 'Tipi');
    data.addColumn('number', 'Rezultati');
    data.addColumn({ type: 'string', role: 'style' });
    data.addColumn({ type: 'string', role: 'annotation' });

    let diff = (p1.votes > p2.votes ? p1.votes - p2.votes : p2.votes - p1.votes);
    let perc = Math.round((p1.votes > p2.votes ? diff / p1.votes : diff / p2.votes) * 100);

    data.addRow(['DIFERENCA NE VOTA', diff, 'lightblue', '' + diff]);
    data.addRow(['DIFERENCA NE PERQINDJE', diff, 'lightblue', '' + perc + '%']);

    const options = {
      legend: 'none',
      backgroundColor: {
        fill: 'transparent'
      },
      // width: 300,
      height: 300,
      chartArea: {
        height: '70%',
        width: '70%'
      },
      hAxis: {
        viewWindowMode: 'explicit',
        viewWindow: {
          max: p1.votes > p2.votes ? p1.votes : p2.votes,
          min: 0
        },
        textStyle:{color: '#7D7D7D', bold: false, fontSize: 20}
      },
      vAxis: {
        textStyle:{color: '#7D7D7D', bold: false, fontSize: 20}
      },
      animation: {
        startup: true,
        duration: 1500,
        easing: 'out',
      },
      annotations: {
        // alwaysOutside: true,
        textStyle: {
            fontSize: 20,
            color: '#7D7D7D',
            bold: true
        }
      },
    };

    const chart = new google.visualization.BarChart(document.getElementById('compare-votes'));

    chart.draw(data, options);
  }

  drawCompareVotes(): void {
    let p1: any;
    let p2: any;

    if (this.compareType === 2) {
      // p1 = this.party[this.selectedParty[0]];
      // p2 = this.resultFromDB[this.selectedParty[1]];
      p1 = this.partiesList.filter(p => p.id === parseInt(this.selectedParty1, 10))[0];
      p2 = this.partiesList.filter(p => p.id === parseInt(this.selectedParty2, 10))[0];
    } else if (this.compareType === 3){
      p1 = this.leadersList1.filter(l => l.id === this.selectedLeader1)[0];
      p2 = this.leadersList2.filter(l => l.id === this.selectedLeader2)[0];
    } else if(this.compareType == 1 && this.selectedCity == 99) {
      const partyName = this.partiesList.filter(el => el.id === parseInt(this.selectedParty1, 10))[0].partyName;
      p1 = this.partiesTotalVotes.filter(p => p.partyName == partyName && p.year == this.selectedYear)[0];
      p2 = this.partiesTotalVotes.filter(p => p.partyName == partyName && p.year == this.selectedYear1)[0];
    } else {
      // p1 = this.resultFromDB[this.selectedParty[0]];
      // p2 = this.resultFromDB[6];
      p1 = this.partyCompareList[0];
      p2 = this.partyCompareList[1];
    }

    let data = new google.visualization.DataTable();

    data.addColumn('string', 'Tipi');
    data.addColumn('number', 'Rezultati');
    data.addColumn({ type: 'string', role: 'style' });
    data.addColumn({ type: 'string', role: 'annotation' });



    let diff = (p1.partyVotes > p2.partyVotes ? p1.partyVotes - p2.partyVotes : p2.partyVotes - p1.partyVotes);
    let perc = Math.round((p1.partyVotes > p2.partyVotes ? diff / p1.partyVotes : diff / p2.partyVotes) * 100);

    data.addRow(['DIFERENCA NE VOTA', diff, 'lightblue', '' + diff]);
    data.addRow(['DIFERENCA NE PERQINDJE', diff, 'lightblue', '' + perc + '%']);

    const options = {
      legend: 'none',
      backgroundColor: {
        fill: 'transparent'
      },
      // width: 300,
      height: 300,
      chartArea: {
        height: '70%',
        width: '70%'
      },
      hAxis: {
        viewWindowMode: 'explicit',
        viewWindow: {
          max: p1.votes > p2.votes ? p1.votes : p2.votes,
          min: 0
        },
        textStyle:{color: '#7D7D7D', bold: false, fontSize: 20}
      },
      vAxis: {
        textStyle:{color: '#7D7D7D', bold: false, fontSize: 20}
      },
      animation: {
        startup: true,
        duration: 1500,
        easing: 'out',
      },
      annotations: {
        // alwaysOutside: true,
        textStyle: {
            fontSize: 20,
            color: '#7D7D7D',
            bold: true
        }
      },
    };

    const chart = new google.visualization.BarChart(document.getElementById('compare-votes'));

    chart.draw(data, options);
  }

  createRange(number: number) {
    let items: number[] = [];
    for (let i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }
}
