import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { GjeografiaZgjedhoreService } from './gjeografia-zgjedhore.service';

declare let google: any;

@Component({
  selector: 'app-gjeografia-zgjedhore',
  templateUrl: './gjeografia-zgjedhore.component.html',
  styleUrls: ['./gjeografia-zgjedhore.component.css']
})
export class GjeografiaZgjedhoreComponent implements OnInit, OnChanges {

  @Input() electionsYears: any[] = [];

  selectedYear: number = 2021;
  showMap = false;
  mostVotedParties: any[] = [];

  showPartyVotes = false;
  selectedCityId: number;

  showTableModal = false;
  tableModalPartyVotes: any[] = [];
  tableModalSelectedYear: number;

  selectedCityName: string;
  winingParties: any[] = [];

  constructor(private gjeografiaZgjedhoreService: GjeografiaZgjedhoreService) {
    google.charts.load('current', { 'packages': ['corechart', 'table'] });
    google.charts.setOnLoadCallback();
  }

  ngOnInit() {
    this.getMostVotedParties();
    this.getCityWiningPartiesByYear();
  }

  ngOnChanges() {
    if (this.electionsYears.length > 0) {
      this.selectedYear = this.electionsYears[this.electionsYears.length - 1].year || 2021;
    }
  }

  loadData(year: number): void {
    this.showMap = false;
    this.selectedYear = year;
    this.getMostVotedParties();
    this.getCityWiningPartiesByYear();
  }

  getMostVotedParties(): void {
    this.gjeografiaZgjedhoreService.getMostVotedPartiesElectiveGeo(this.selectedYear).subscribe(response => {
      const res = response as any;
      this.mostVotedParties = res;

      this.drawMostVotedParties();
    });
  }

  getCityWiningPartiesByYear(): void {
    this.gjeografiaZgjedhoreService.getCityWiningPartiesByYear(this.selectedYear).subscribe(response => {
      const res = response as any;
      this.winingParties = res;
      this.showMap = true;
    });
  }

  drawMostVotedParties() {

    if (this.mostVotedParties.length === 0) {
      return;
    }

    // const styles = ['#b87333', 'silver', 'gold', '#e5e4e2'];

    let data = new google.visualization.DataTable();

    data.addColumn('string', 'Party');
    data.addColumn('number', 'Votes');
    data.addColumn({ type: 'string', role: 'style' });
    data.addColumn({ type: 'string', role: 'annotation' });

    for (let i = 0; i < this.mostVotedParties.length; i++) {
      const obj = this.mostVotedParties[i];
      data.addRow([obj.name, parseInt(obj.nrOfVotes.toString(), 10), obj.color, obj.nrOfVotes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")]);
    }

    const options = {
      legend: 'none',
      backgroundColor: {
        fill: 'transparent'
      },
      height: 600,
      width: document.getElementById('subjektet-fituese').clientWidth,
      legendTextStyle: {
        bold: true
      },
      chartArea: {
        height: '70%',
        width: '70%'
      },
      hAxis: {
        textStyle:{color: '#7D7D7D', bold: true, fontSize: 20}
      },
      vAxis: {
        textStyle:{color: '#7D7D7D', bold: true, fontSize: 20}
      },
      animation: {
        startup: true,
        duration: 1500,
        easing: 'out',
      },
      annotations: {
        // alwaysOutside: false,
        textStyle: {
            fontSize: 20,
            color: '#7D7D7D',
            bold: true
        }
      },
    };

    const chart = new google.visualization.BarChart(document.getElementById('subjektet-fituese'));
    chart.draw(data, options);
  }

  cityClicked(event: any): void {

    this.showPartyVotes = true;
    this.selectedCityId = event.cityId;

    this.gjeografiaZgjedhoreService.getPartyVotesByCity(event.cityId).subscribe(response => {
      const res = response as any;

      this.selectedCityName = res.citySVG.name;

      const partyVotes = res.partyVotesByCity;

      this.electionsYears.forEach(year => {
        this[`partyVotes${year}`] = partyVotes.filter(p => p.year === year);
      });
      // this.partyVotes2019 = partyVotes.filter(p => p.year === 2019);
      // this.partyVotes2017 = partyVotes.filter(p => p.year === 2017);
      // this.partyVotes2014 = partyVotes.filter(p => p.year === 2014);
      // this.partyVotes2010 = partyVotes.filter(p => p.year === 2010);
      // this.partyVotes2007 = partyVotes.filter(p => p.year === 2007);
      // this.partyVotes2004 = partyVotes.filter(p => p.year === 2004);
      // this.partyVotes2001 = partyVotes.filter(p => p.year === 2001);
    });

  }

  openDialog(year: number): void {
    this.showTableModal = true;
    this.tableModalSelectedYear = year;
    this.tableModalPartyVotes = this[`partyVotes${year}`];
  }

  closePartyVotes(): void {
    this.showPartyVotes = false;
      setTimeout(() => {
        this.drawMostVotedParties();
      }, 1000);
  }
}
