import { Component, OnInit } from '@angular/core';
import { imgBaseUrl } from '../../parlamentare/parlamentare';
import { cities } from '../../parlamentare/kosovo-map/kosovo-map';

@Component({
  selector: 'app-historiku',
  templateUrl: './historiku.component.html',
  styleUrls: ['./historiku.component.css']
})
export class HistorikuComponent implements OnInit {
  selectedYear = 2021;
  electionYearsPerView: any = {
    "Lokale": [2013, 2014, 2017, 2019, 2021],
    "Parlamentare": [2013, 2014, 2017, 2019, 2021],
  }

  mainViews: string[] = ["Parlamentare"]; //["Lokale", "Parlamentare"];
  selectedView = "Parlamentare";

  cityViews: string[] = ["Parlamentare"]; //["Asamble", "Kryetar", "Parlamentare"];
  selectedCityView = "Parlamentare";

  electionsYears: number[] = this.electionYearsPerView[this.selectedView];

  selectedCity: number = -1;
  selectedCityName: string = "";

  partyVotes = []

  imgBaseUrl = '';

  constructor() { 
    this.imgBaseUrl = imgBaseUrl();
  }

  ngOnInit() {
  }

  loadYear(year: number): void {
    this.selectedYear = year;
    switch (year) {
      case 2013:
        this.loadView("Lokale"); 
        this.mainViews = ["Lokale"];
        this.cityViews = ["Asamble", "Kryetar"];
        break;
      case 2014:
      case 2019:
      case 2021:
        this.loadView("Parlamentare"); 
        this.mainViews = ["Parlamentare"];
        this.cityViews = ["Parlamentare"];
        break;
      default:
        this.mainViews = ["Lokale", "Parlamentare"];
        this.cityViews = ["Asamble", "Kryetar", "Parlamentare"];
        break;
    }
  }

  loadView(view: string): void {
    this.selectedView = view;
    if (view === "Lokale") {
      this.selectedCityView = "Kryetar";
    } else {
      this.selectedCityView = "Parlamentare";
    }
    this.electionsYears = this.electionYearsPerView[view]; 
    if (this.electionsYears && !this.electionsYears.includes(this.selectedYear)) {
      this.selectedYear = this.electionsYears[this.electionsYears.length - 1];
    }
  }

  loadCityView(view: string): void {
    this.selectedCityView = view;
  }

  loadCity(data: any) {
    this.selectedCity = data.cityId;
    this.selectedCityName = cities.filter(c => c.id == data.cityId)[0].name.toUpperCase();
  }

  backToMainView(): void {
    this.selectedCity = -1;
    this.selectedCityName = "";
    if (this.selectedView === "Lokale") {
      this.selectedCityView = "Kryetar";
    } else {
      this.selectedCityView = "Parlamentare";
    }
  }

  backToMainViewFromAnalyses(): void {
    this.loadView("Parlamentare");
  }
}
