import { Component, OnDestroy, OnInit } from '@angular/core';
import { ParalamentareService } from '../paralamentare.service';

declare let google: any;

@Component({
  selector: 'app-parashikimet-analistave',
  templateUrl: './parashikimet-analistave.component.html',
  styleUrls: ['./parashikimet-analistave.component.css','../pjesemarrja/pjesemarrja.component.css']
})
export class ParashikimetAnalistaveComponent implements OnInit, OnDestroy {

  harta = true;
  statistikat = false;
  newAnalysis = true;

  selectedPartyId = 0;

  mapAnalyses: any;
  showMapAnalysis = false;
  oldAnalysis: any;

  chairsAnalyses: any[] = [];
  selectedChairsAnalyses: any;
  showChairsAnalysis = false;
  oldChairsAnalysis: any;

  cityClickedObj: any;
  partyListStock = [];
  
  tePaselektuara = {
    name: 'PASELEKTUARA',
    color: 'white',
    value: false,
    percentage: 0,
    selectedCities: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36]
  };

  partyList: any[] = [];

  selectedParty: string;

  analystName: string;

  constructor(private paralamentareService: ParalamentareService) {
    google.charts.load('current', { 'packages': ['corechart', 'table'] });
    google.charts.setOnLoadCallback(this.drawChart.bind(this));
  }

  ngOnInit() {
    this.paralamentareService.getAvailablePartiesToPredict().subscribe(response => {
      const res = response as any;
      this.partyList = [];
      res.forEach(i => {
        this.partyList.push({
          name: i.name,
          color: i.color,
          value: false,
          chairs: i.name === 'KOMUNITETET' ? 20 : 0,
          selectedCities: []
        });
      });
      this.partyList.push(this.tePaselektuara);
      this.partyList[0].value = true;
    });
    this.partyListStock = [];
    this.partyList.forEach(i => this.partyListStock.push(Object.assign({}, i)));

    if(this.harta) {
      this.initParashikimiHartes();
    } else {
      this.initParashikimiUleseve();
    }

    this.loadScript();
  }

  ngOnDestroy() {
    this.removeScript();
  }

  changeTab(harta: boolean): void {
    this.harta = harta;
    this.partyList.sort(( a, b ) => {
      if ( a.name[0] < b.name[0] ) {
        return -1;
      }
      if ( a.name[0] > b.name[0] ) {
        return 1;
      }
      return 0;
    });

    if(this.harta) {
      this.initParashikimiHartes();
    } else {
      this.initParashikimiUleseve();
    }
  }

  initParashikimiHartes(): void {
    this.paralamentareService.getAnalystPredictions().subscribe(response => {
      this.mapAnalyses = response;
    });
  }

  initParashikimiUleseve(): void {
    this.paralamentareService.getChairsPredictions().subscribe(response => {
      this.chairsAnalyses = response as any;
      this.showChairsAnalysis = false;
      this.analystName = '';
      this.partyList.forEach(el => {
        el.chairs = el.name === 'KOMUNITETET' ? 20 : 0;
      });
    });
  }

  showOldAnalyse() {
    const analysis = this.mapAnalyses.filter(item => item.id === parseInt(this.oldAnalysis))[0];

    const data = JSON.parse(analysis.selectedCitites);
    const percentage = JSON.parse(analysis.partyPercentage);

    this.partyList.forEach(i => {
      // debugger
      i.selectedCities = data.cities[`${i.name}`];
      // const partyPercentage = percentage[`${i.name}`];
      // i.percentage = partyPercentage ? partyPercentage.percentage : 0;
    });

    this.analystName = analysis.name;

    this.newAnalysis = false;
    this.showMapAnalysis = true;

    this.drawChart();
  }

  showOldChairsAnalyse(): void {
    const analysis = this.chairsAnalyses.filter(item => item.id === parseInt(this.selectedChairsAnalyses))[0];

    const partyChairs = JSON.parse(analysis.chairs)

    this.partyList.forEach(i => {
      i.chairs = partyChairs[`${i.name}`] !== undefined ? partyChairs[`${i.name}`].chairs || 0 : 0;
    });

    this.partyList.sort(( a, b ) => {
      if ( a.chairs < b.chairs ) {
        return 1;
      }
      if ( a.chairs > b.chairs ) {
        return -1;
      }
      return 0;
    });

    this.analystName = analysis.name;

    this.showChairsAnalysis = true;

    // this.drawChart();
    let flourish = document.querySelector(".flourish-embed iframe");
    document.querySelector(".flourish-embed")['style'].pointerEvents = "none";
    let svgChairs = flourish['contentWindow'].document.body.querySelectorAll('circle');
    
    svgChairs.forEach(chair => {
      chair.setAttribute("fill","white")
      chair.setAttribute("stroke","transparent")
    });
    
    let chairsFilled = 0;
    this.partyList.forEach(party => {
      for (let i=0; i<party.chairs; i++) {
        svgChairs[chairsFilled+i].setAttribute("fill",party.color)
      }
      chairsFilled += party.chairs;
    });
  }

  cityClicked(event: any): void {
    if (!event) {
      return;
    }

    const city = event.cityId;
    let remCity = -1;
    let fromParty = -1;

    for (let i = 0; i < this.partyList.length - 1; i++) {
      let id = this.partyList[i].selectedCities.indexOf(city);
      if (id !== -1) {
        remCity = id;
        fromParty = i;
      }
    }

    if (remCity !== -1 && fromParty !== -1) {
      this.partyList[fromParty].selectedCities = this.partyList[fromParty].selectedCities.filter(item => item !== event.cityId);
      this.partyList[this.partyList.length - 1].selectedCities.push(city);
    } else {
      this.partyList[this.selectedPartyId].selectedCities.push(city);
      this.partyList[this.partyList.length - 1].selectedCities = this.partyList[this.partyList.length - 1].selectedCities.filter(item => item !== event.cityId);
    }

    this.drawChart();
  }

  saveMapPrediction(): void {
    this.newAnalysis = false;
    this.showMapAnalysis = true;

    let obj = {};
    let percentage = {};
    this.partyList.forEach(party => {
      obj[`${party.name}`] = party.selectedCities;
      percentage[`${party.name}`] = {
        percentage: party.percentage,
        color: party.color
      };
    });


    const cititesJson = JSON.stringify({ cities: obj });
    const percentageJson = JSON.stringify(percentage);
    const data = {
      Name: this.analystName,
      SelectedCitites: cititesJson,
      PartyPercentage: percentageJson
    };

    this.paralamentareService.saveAnalystPrediction(data).subscribe(response => {
      // this.drawChart();
    });
  }

  saveChairsPrediction() {

    let chairs = {};
    this.partyList.forEach(party => {
      chairs[`${party.name}`] = {
        chairs: party.chairs,
        color: party.color
      };
    });

    const data = {
      Name: this.analystName,
      Chairs: JSON.stringify(chairs)
    };

    this.paralamentareService.saveChairsPrediction(data).subscribe( _ => {
      this.showChairsAnalysis = true;

      this.partyList.sort(( a, b ) => {
        if ( a.chairs < b.chairs ) {
          return 1;
        }
        if ( a.chairs > b.chairs ) {
          return -1;
        }
        return 0;
      });

          // this.drawChart();
        let flourish = document.querySelector(".flourish-embed iframe");
        document.querySelector(".flourish-embed")['style'].pointerEvents = "none";
        let svgChairs = flourish['contentWindow'].document.body.querySelectorAll('circle');
        
        svgChairs.forEach(chair => {
          chair.setAttribute("fill","white")
          chair.setAttribute("stroke","transparent")
        });
        
        let chairsFilled = 0;
        this.partyList.forEach(party => {
          for (let i=0; i<party.chairs; i++) {
            svgChairs[chairsFilled+i].setAttribute("fill",party.color)
          }
          chairsFilled += party.chairs;
        });
    });
  }

  save(): void {
    if (this.analystName === "") {
      return;
    }
    if(this.harta) {
      this.saveMapPrediction();
    } else {
      this.saveChairsPrediction();
    }
  }

  makeNewAnalysis(): void {
    this.analystName = '';
    this.oldAnalysis = '-1';
    this.newAnalysis = true;
    this.showMapAnalysis = false;
    // this.partyList = [];
    const tePaselektuaraAll = {
      name: 'PASELEKTUARA',
      color: 'white',
      value: false,
      percentage: 0,
      selectedCities: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36]
    };
    this.tePaselektuara = Object.assign({}, tePaselektuaraAll);
    this.ngOnInit();
  }

  newChairsAnalysis(): void {
    this.initParashikimiUleseve();
    this.showChairsAnalysis = false;
    this.analystName = '';
    this.partyList.forEach(el => {
      el.chairs = el.name === 'KOMUNITETET' ? 20 : 0;
    });
  }

  selectParty(id: number) {
    this.partyList[id].value = true;
    if (id != this.selectedPartyId) {
      this.partyList[this.selectedPartyId].value = false;
    }
    this.selectedPartyId = id;
  }

  plusMinusOne(p: number, v): void {

    let val = this.partyList[p].chairs + parseInt(v, 10);

    if (val < 0) {
      return;
    }
    // const total = this.calcTotalPercentage(p, val);
    const otherPartiesChairs = this.calcTotal(p);
    // if (total > 100) {
    //   val = val - (total - 100);
    // }
    // this.partyList[p].chairs = val < 0 ? 0 : val;

    if ((otherPartiesChairs + val) >= 100) {
      const v = 100 - otherPartiesChairs;
      this.partyList[p].chairs = v;
      // $event.target.value = v;
    } else {
      this.partyList[p].chairs = val;
    }
  }

  percentagePredict(p: number, $event: any, value) {

    let val = parseInt($event.target.value) || parseInt(value, 10);
    // let total = this.calcTotalPercentage(p, val);
    const otherPartiesChairs = this.calcTotal(p);
    // if (total > 100) {
    //   val = val - (total - 100);
    //   $event.target.value = val;
    // }

    if ((otherPartiesChairs + val) >= 100) {
      const v = 100 - otherPartiesChairs;
      this.partyList[p].chairs = v;
      $event.target.value = v;
    } else {
      this.partyList[p].chairs = val;
    }
    
  }

  calcTotal(index: number): number {
    let otherPartiesChairs = 0;
    const partyName = this.partyList[index].name;
    this.partyList.forEach(p => {
      if (p.name !== partyName && p.name !== 'KOMUNITETET') {
        otherPartiesChairs += p.chairs || 0;
      }
    });
    return otherPartiesChairs;
  }

  // remove this method
  calcTotalPercentage(n: number, v: number) {
    let t: number = 0;
    let counts = 0;
    this.partyList.forEach(p => {
      t += (counts != n) ? parseInt(p.chairs) : v;
      counts++;
    });
    return t;
  }

  createRange(number: number) {
    let items: number[] = [];
    for (let i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }

  clearChairsPrediction(): void {
    this.analystName = '';
    this.partyList = [];
  }

  async drawChart() {
    this.drawPredictedVotes();
    return;

    let pieData = [
      ['Partia', 'Numri i uleseve']
    ];
    let c = [];

    let teTjera = 100;
    

    this.partyList.forEach(item => {
      const el = [
        item.name,
        parseInt(item.chairs)
      ];

      teTjera -= parseInt(item.chairs);

      pieData.push(el);
      c.push(item.color); 
    });

    if (teTjera > 0) {
      const el = [
        'TE TJERA',
        teTjera as any
      ];
      pieData.push(el);
      c.push('#ee83fc');
    }

    const data = google.visualization.arrayToDataTable(pieData);

    const options = {
      // title: 'Fituesit e qyteteve'
      backgroundColor: { fill: 'transparent' },
      animation: {
        startup: true,
        duration: 2000,
        easing: 'out',
      },
      colors: c,
      pieSliceBorderColor: '#575654',
      width: 900,
      height: 400,
      legend: {
        alignment: 'center',
        position: 'left',
        textStyle: {
          fontSize: 16,
          color: '#7D7D7D',
          bold: true
        }
      },
      annotations: {
        // alwaysOutside: true,
        textStyle: {
            fontSize: 30,
            color: '#7D7D7D',
            bold: true
        }
      },
      pieSliceTextStyle: {
        color: 'white',
        // fontSize: 20,
        bold: true
      },
      fontSize: 14,
      bold: true
    };
    await this.sleep(500);

    const chart = new google.visualization.BarChart(document.getElementById('stats-chart'));
    chart.draw(data, options);

  }

  async drawPredictedVotes() {
    if (this.partyList.length === 0) {
      return;
    }

    let data = new google.visualization.DataTable();
    let dataColors = [];

    data.addColumn('string', 'Party');
    data.addColumn('number', 'Numri i votave');
    data.addColumn({ type: 'string', role: 'style' });
    data.addColumn({ type: 'string', role: 'annotation' });

    for (let i = 0; i < this.partyList.length; i++) {
      const obj = this.partyList[i];
      data.addRow([obj.name, parseInt(obj.chairs.toString(), 10), obj.color, obj.chairs.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")]);
      dataColors.push(obj.color)
    }

    const options = {
      legend: 'none',
      backgroundColor: {
        fill: 'transparent'
      },
      width: 700,
      height: 500,
      legendTextStyle: {
        bold: true
      },
      colors: dataColors,
      hAxis: {
        textStyle:{color: '#7D7D7D', bold: true, fontSize: 15,}
      },
      vAxis: {
        textStyle:{color: '#7D7D7D', bold: true, fontSize: 15,}
      },
      chartArea: {
        height: '70%',
        width: '60%'
      },
      animation: {
        startup: true,
        duration: 1500,
        easing: 'out',
      },
      annotations: {
        // alwaysOutside: true,
        textStyle: {
            fontSize: 20,
            color: '#7D7D7D',
            bold: true
        }
      },
    };
    await this.sleep(500);

    const chart = new google.visualization.BarChart(document.getElementById('stats-chart'));

    chart.draw(data, options);
  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  removeScript() {
    document.getElementById("flourish.embed.js").remove();
  }

  loadScript() {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '/koalicionet/flourish.embed.js?cachebuster='+ new Date().getTime();
    script.id = 'flourish.embed.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
    setTimeout(function() {
      // ctx.loadData(2019);
      if (document.querySelector(".flourish-embed .flourish-credit")) {
        document.querySelector(".flourish-embed .flourish-credit")['style'].display = "none";
      }
    }, 1000);
  }

}
