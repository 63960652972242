<!-- <div id='newAnalysis' *ngIf="newAnalysis"> -->
    

<!-- Menu -->
<div class="row content-center displayContentCenter" style="margin-top: 50px;">
    <div class="w3-container" style="width: 60%; margin-bottom: 30px">
        <div class="w3-row">
          <a href="javascript:void(0);" (click)="changeTab(true)">
            <div class="w3-half tablink w3-bottombar w3-hover-light-grey w3-padding"
                [ngStyle]="{'border-color': harta ? '#e9ca17 ' : '#CBCBCB'}"><b>Parashikimi i Hartes</b></div>
          </a>
          <a href="javascript:void(0);" (click)="changeTab(false)">
            <div class="w3-half tablink w3-bottombar w3-hover-light-grey w3-padding"
                [ngStyle]="{'border-color': !harta ? '#e9ca17 ' : '#CBCBCB'}"><b>Parashikimi me Ulese</b></div>
          </a>
        </div>
    </div>
    <!-- <div class="col-sm">
        <button
            type="button"
            [ngClass]="harta ? 'content-right btn btn-success active-btn' : 'content-right btn btn-success btnYearColor'"
            (click)="changeTab(true)"
        >Parashikimi i Hartes</button>
    </div>
    <div class="col-sm">
        <button
            type="button"
            [ngClass]="!harta ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'"
            (click)="harta = false"
            (click)="changeTab(false)"
        >Parashikimi me ulese</button>
    </div> -->
</div>

<!-- Map prediction -->

<div *ngIf="harta">
    <!-- Predict menu -->
    <div class="d-flex justify-content-center" *ngIf="!showMapAnalysis">
        <div class="col-sm-2"></div>
        <div class="col-sm">
            <div class="row">
                    <h4 class="content-right" style="color: #7D7D7D">Parashikuesi: </h4>
                    <input type="text" 
                        [(ngModel)]="analystName" 
                        class="btn content-right" 
                        style="width: 60%; background-color: white; margin-right: 15px; margin-left: auto;" 
                        placeholder="Emri"/>
            </div>
            
        </div>
        <div class="col-sm">
            <button type="button" class="btn btn-primary" (click)="save()" >Ruaj Parashikimin</button>
        </div>
        <div class="col-sm-3" style="margin-top: -45px; margin-bottom: 30px;">
            <div class="row">
                <h4 style="color: #7D7D7D; padding-right: 15px">Analizat e kaluara:</h4>
            </div>
            <div class="row">
                <select class="btn" style="background-color: white; width: 80%;"
                        [(ngModel)]="oldAnalysis" 
                        (change)="showOldAnalyse()">
                    <option [value]="-1"></option>
                    <option *ngFor="let o of mapAnalyses;" [value]="o.id">{{ o.name }}</option>
                </select>
            </div>
        </div>
    </div>

    <!-- Body -->
    <div class="row content-center" *ngIf="!showMapAnalysis">
        <ng-template ngFor let-p [ngForOf]="createRange(partyList.length - 1)" let-i="index">
            <div>
                <button
                    type="button"
                    style="background-color: white; margin-right: 15px; color: white"
                    class="{{partyList[i].value ? 'btn selected-party' : 'btn'}}"
                    (click)="selectParty(i)"
                    [ngStyle]="{'background-color': partyList[i].color}"
                >{{ partyList[i].name }}</button> <!--{{ partyList[i].value ? ' >' : '' }}-->
            </div>
        </ng-template>
    </div>

    <div class="row" *ngIf="!showMapAnalysis">
        <div class="col-sm"></div>
        <div class="col-sm-6" style="padding-top: 20px; padding-left: 50px">
            <div style="width: 80%;">
                    <app-kosovo-map 
                    [cityFillColor]="partyList[selectedPartyId] ? partyList[selectedPartyId].color : ''"
                    [clickMode]="2"
                    (cityClickHandeled)='cityClicked($event)'>
                </app-kosovo-map>
            </div>
        </div>
        <div class="col-sm"></div>
    </div>

    <!-- Previous analysis -->

    <div id="showAnalysis" *ngIf="showMapAnalysis">
        <div class="row">
            <div class="col-sm">
                <div class="row">
                        <h4 style="color: #7D7D7D; margin-left: auto; margin-right: 10px;">Parashikuesi: </h4>
                        <input disabled type="text" 
                            [(ngModel)]="analystName" 
                            class="btn content-right" 
                            style="width: 35%; background-color: white; margin-right: 15px;" 
                            placeholder="Emri"/>
                </div>
            </div>
            <div class="col-sm">
                <button type="button" class="btn btn-primary" (click)="makeNewAnalysis()" >Parashikim i ri</button>
            </div>
        </div>

        <div class="row content-center" style="padding-top: 45px">
            <ng-template ngFor let-p [ngForOf]="createRange(partyList.length - 1)" let-i="index">
                <div>
                    <button
                        type="button"
                        style="background-color: white; margin-right: 15px; color: white"
                        class="{{partyList[i].value ? 'btn selected-party' : 'btn'}}"
                        [ngStyle]="{'background-color': partyList[i].color}"
                    >{{ partyList[i].name }}</button> <!--{{ partyList[i].value ? ' >' : '' }}-->
                </div>
            </ng-template>
        </div>
    
        <div class="row">
            <div class="col-sm"></div>
            <div class="col-sm-6" style="padding-top: 20px; padding-left: 50px">
                <div style="width: 80%;">
                    <app-kosovo-map [cityFillByAnalist]='partyList' (cityClickHandeled)="cityClicked($event)" [clickMode]="2" [clickable]="false"></app-kosovo-map>
                </div>
            </div>
            <div class="col-sm"></div>
        </div>
    </div>
</div>


<!-- Chairs Prediction -->

<div *ngIf="!harta">

<!-- Menu  -->
    <div class="d-flex justify-content-center" *ngIf="!showChairsAnalysis">
        <div class="col-sm-2"></div>
        <div class="col-sm">
            <div class="row">
                    <h4 class="content-right" style="color: #7D7D7D">Parashikuesi: </h4>
                    <input type="text" 
                        [(ngModel)]="analystName" 
                        class="btn content-right" 
                        style="width: 60%; background-color: white; margin-right: 15px; margin-left: auto;" 
                        placeholder="Emri"/>
            </div>

        </div>
        <div class="col-sm">
            <button type="button" class="btn btn-primary" (click)="save()" >Ruaj Parashikimin</button>
        </div>
        <div class="col-sm-3" style="margin-top: -45px; margin-bottom: 30px;">
            <div class="row">
                <h4 style="color: #7D7D7D; padding-right: 15px">Analizat e kaluara:</h4>
            </div>
            <div class="row">
                <select class="btn" style="background-color: white; width: 80%;"
                        [(ngModel)]="selectedChairsAnalyses" 
                        (change)="showOldChairsAnalyse()">
                    <option [value]="-1"></option>
                    <option *ngFor="let o of chairsAnalyses;" [value]="o.id">{{ o.name }}</option>
                </select>
            </div>
        </div>
    </div>

    <div id="statistikat" style="padding-top: 50px" *ngIf="!showChairsAnalysis">
        <ng-template ngFor let-p [ngForOf]="createRange(partyList.length - 1)" let-i="index">
            <div class="row" style="color: #7D7D7D">
                <div class="col-sm"></div>
                <div class="col-sm-2 content-center">
                    <h2 class="content-right">{{ partyList[i].name }}</h2>
                </div>
                <div class="col-sm content-center" style="color: #7D7D7D; padding-right: 0;" (click)="plusMinusOne(i, -1)"><i class="material-icons">remove</i></div>
                <div class="col-sm-4 content-center">
                    <input type="range" (input)="percentagePredict(i, $event)" class="custom-range" min="0" [value]="partyList[i].chairs" max="100" />
                </div>
                <div class="col-sm content-center" style="color: #7D7D7D; padding-left: 0;" (click)="plusMinusOne(i, 1)"><i class="material-icons">add</i></div>
                <div class="col-sm-2">
                    <h2>{{ partyList[i].chairs }}</h2>
                </div>
                <div class="col-sm"></div>
            </div>
        </ng-template>
    </div>

</div>

<div id="showAnalysis" [ngStyle]="{'height': showChairsAnalysis ? 'auto':'0', 'overflow': 'hidden'}">
    <div class="row">
        <div class="col-sm">
            <div class="row">
                    <h4 style="color: #7D7D7D; margin-left: auto; margin-right: 10px;">Parashikuesi: </h4>
                    <input disabled type="text" 
                        [(ngModel)]="analystName" 
                        class="btn content-right" 
                        style="width: 30%; background-color: white; margin-right: 15px;" 
                        placeholder="Emri"/>
            </div>

        </div>
        <div class="col-sm">
            <button type="button" class="btn btn-primary" (click)="newChairsAnalysis()" >Parashikim i ri</button>
        </div>
    </div>

    <div class="d-flex flex-row displayContentCenter">
        <div class="row displayContentCenter" style="min-height: 500px;">
            <div class="col-sm-4">
                <!-- <div id="stats-chart" style="width: 100%; height: 100%;">
                </div> -->
                <div class="row" [hidden]="personView" style="width: 90%;" *ngFor="let party of partyList; let i = index">
                    <button
                        type="button"
                        style="width: 100% !important; background-color: white; margin-top: 10px; overflow: hidden; border-radius: 20px 0 0 20px !important;"
                        [ngStyle]="{'background-color': (selectedPartyName === party ? 'rgba(255,255,0,0.2)' : 'white'), 'border-left': '20px solid ' + party.color}"
                        class="btn"
                    >{{ party.name }} <b class="ministers-number">{{ party.chairs }}</b></button>
                </div>
            </div>
            <div class="col-sm-2"></div>
            <div class="col-sm-6 align-self-center">
                <div class="p-2 align-self-center" style="height: 100%; width: 100%; margin-left: 50px;">
                    <!-- <app-uleset-ne-parlament [year]="yearToLoad" [parties]="parties" ></app-uleset-ne-parlament> -->
                    <div class="d-flex flex-row displayContentCenter">
                        <div class="flourish-embed" data-src="/koalicionet/index.html">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-sm-3"></div>
        <div class="col-sm-4">
            <div id="stats-chart" style="width: 100%; height: 100%;"></div>
        </div>
        <div class="col-sm-3"></div>
    </div> -->
</div>




<!-- 



    <div class="row content-center">
        <div class="col-sm-3"></div>
        <div class="col-sm">
            <div class="row">
                    <h4 class="content-right" style="color: white">Parashikuesi: </h4>
                    <input type="text" 
                        [(ngModel)]="analystName" 
                        class="btn content-right" 
                        style="width: 70%; background-color: white; margin-right: 15px; margin-left: auto;" 
                        placeholder="Emri"/>
            </div>
            
        </div>
        <div class="col-sm">
            <button type="button" class="btn btn-primary" (click)="save()" >Ruaj Parashikimin</button>
        </div>
        <div class="col-sm-3">
            <div *ngIf="harta; else elseBlock">
                <div class="row">
                    <h4 style="color: white; padding-right: 15px">Analizat e kaluara:</h4>
                    <select class="btn" style="background-color: white" 
                            [(ngModel)]="oldAnalysis" 
                            (change)="showOldAnalyse()">
                        <option [value]="-1"></option>
                        <option *ngFor="let o of mapAnalyses;" [value]="o.id">{{ o.name }}</option>
                    </select>
                </div>
            </div>
            <ng-template #elseBlock>
                <div class="row">
                    <h4 style="color: white; padding-right: 15px">Analizat e kaluara:</h4>
                    <select class="btn" style="background-color: white" 
                            [(ngModel)]="selectedChairsAnalyses" 
                            (change)="showOldChairsAnalyse()">
                        <option [value]="-1"></option>
                        <option *ngFor="let o of chairsAnalyses;" [value]="o.id">{{ o.name }}</option>
                    </select>
                </div>
            </ng-template>

            
        </div>
    </div>

    <div id="harta" [hidden]="!harta">
        
        <div class="row content-center">
            <ng-template ngFor let-p [ngForOf]="createRange(partyList.length - 1)" let-i="index">
                <div>
                    <button
                        type="button"
                        style="background-color: white; margin-right: 15px; color: white"
                        class="{{partyList[i].value ? 'btn selected-party' : 'btn'}}"
                        (click)="selectParty(i)"
                        [ngStyle]="{'background-color': partyList[i].color}"
                    >{{ partyList[i].name }}</button> 
                </div>
            </ng-template>
        </div>
        <div class="row">
            <div class="col-sm"></div>
            <div class="col-sm-4" style="padding-top: 20px; padding-left: 50px">
                <div style="width: 80%;">
                        <app-kosovo-map 
                        [cityFillColor]="partyList[selectedPartyId] ? partyList[selectedPartyId].color : ''"
                        [clickMode]="2"
                        (cityClickHandeled)='cityClicked($event)'>
                    </app-kosovo-map>
                </div>
            </div>
            <div class="col-sm"></div>
        </div>
    </div>
    
    <div id="statistikat" *ngIf="!showChairsAnalysis" [hidden]="harta"  style="padding-top: 50px">
        <ng-template ngFor let-p [ngForOf]="createRange(partyList.length - 1)" let-i="index">
            <div class="row" style="color: white">
                <div class="col-sm"></div>
                <div class="col-sm-2 content-center">
                    <h2 class="content-right">{{ partyList[i].name }}</h2>
                </div>
                <div class="col-sm content-center" style="color: white; padding-right: 0;" (click)="plusMinusOne(i, -1)"><i class="material-icons">remove</i></div>
                <div class="col-sm-4 content-center">
                    <input type="range" (input)="percentagePredict(i, $event)" class="custom-range" min="0" [value]="partyList[i].chairs" max="100" />
                </div>
                <div class="col-sm content-center" style="color: white; padding-left: 0;" (click)="plusMinusOne(i, 1)"><i class="material-icons">add</i></div>
                <div class="col-sm-2">
                    <h2>{{ partyList[i].chairs }}</h2>
                </div>
                <div class="col-sm"></div>
            </div>
        </ng-template>
    </div>
</div>

<div id="showAnalysis" *ngIf="showMapAnalysis">
    <div class="row" style="padding-top: 50px">
        <div class="col-sm">
            <div class="row">
                    <h4 style="color: white; margin-left: auto; margin-right: 10px;">Parashikuesi: </h4>
                    <input disabled type="text" 
                        [(ngModel)]="analystName" 
                        class="btn content-right" 
                        style="width: 30%; background-color: white; margin-right: 15px;" 
                        placeholder="Emri"/>
            </div>
            
        </div>
        <div class="col-sm">
            <button type="button" class="btn btn-primary" (click)="makeNewAnalysis()" >Parashikim i ri</button>
        </div>
    </div>

    <div class="row" style="padding-top: 50px">
        <div class="col-sm-3"></div>
        <div class="col-sm-4" style="padding-top: 20px; padding-left: 50px">
            <app-kosovo-map [cityFillByAnalist]='partyList' (cityClickHandeled)="cityClicked($event)" [hover]="false" [clickMode]="1"></app-kosovo-map>
        </div>
        <div class="col-sm-3"></div>
    </div>
</div>

 -->

