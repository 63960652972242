import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getBaseUrl } from '../../../main';

@Injectable({
  providedIn: 'root',
})
export class PjesemarrjaService {
  baseUrl: string;

  constructor(private http: HttpClient) { 
    this.baseUrl = getBaseUrl();
  }

  getParticipationStatistics() {
    return this.http.get(`${this.baseUrl}api/elections/GetParticipationStatistics`);
  }

  saveParticipationStatistics(data) {
    return this.http.post(`${this.baseUrl}api/elections/SaveParticipationStatistics`, JSON.stringify(data));
  }

  editParticipationStatistics(data) {
    return this.http.put(`${this.baseUrl}api/elections/EditParticipationStatistics`, JSON.stringify(data));
  }

  deleteParticipationStatistics(id: number) {
    return this.http.delete(`${this.baseUrl}api/elections/DeleteParticipationStatistics/${id}`);
  }
}
