import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { HistorikuParlamentareService } from '../paralamentare/parlamentare.service';
import { ParalamentareService } from '../../../parlamentare/paralamentare.service';
import { imgBaseUrl } from '../../../parlamentare/parlamentare';

declare let google: any;

@Component({
  selector: 'app-historiku-analizat',
  templateUrl: './analizat.component.html',
  styleUrls: ['./analizat.component.css','../historiku.component.css']
})
export class HistorikuAnalizatComponent implements OnInit, OnChanges {

  @Input() loadYear: number = 2021;
  @Output() backToMainView: EventEmitter<any> = new EventEmitter();

  selectedParty: string = "";
  selectedPartyId = 0;

  tePaselektuara = {
    name: 'PASELEKTUARA',
    color: 'white',
    value: false,
    percentage: 0,
    selectedCities: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36]
  };

  partyList: any[] = [];
  partyListStock = [];
  mapAnalyses: any;
  oldAnalysis: any;
  analystName: string;
  newAnalysis: boolean = true;
  showMapAnalysis: boolean = false;

  imgBaseUrl = "";

  constructor(private service: HistorikuParlamentareService, private paralamentareService: ParalamentareService) {
    this.imgBaseUrl = imgBaseUrl();
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnChanges() {
    // if (this.loadYear !== this.selectedYear) {
    //   this.loadData();
    // }
  }

  loadData(): void {
    this.paralamentareService.getAvailablePartiesToPredict().subscribe(response => {
      const res = response as any;
      this.partyList = [];
      res.forEach(i => {
        this.partyList.push({
          name: i.name,
          color: i.color,
          value: false,
          chairs: i.name === 'KOMUNITETET' ? 20 : 0,
          selectedCities: []
        });
      });
      this.partyList.push(this.tePaselektuara);
      this.partyList[0].value = true;
    });
    this.partyListStock = [];
    this.partyList.forEach(i => this.partyListStock.push(Object.assign({}, i)));

    this.initParashikimiHartes();
  }

  selectParty(partyIndex: any): void {
    if (this.selectedPartyId === partyIndex) {
      this.selectedPartyId = 0;
    } else {
      this.selectedPartyId = partyIndex;
    }
  }

  cityClicked(event: any): void {
    if (!event) {
      return;
    }

    const city = event.cityId;
    let remCity = -1;
    let fromParty = -1;

    for (let i = 0; i < this.partyList.length - 1; i++) {
      let id = this.partyList[i].selectedCities.indexOf(city);
      if (id !== -1) {
        remCity = id;
        fromParty = i;
      }
    }

    if (remCity !== -1 && fromParty !== -1) {
      this.partyList[fromParty].selectedCities = this.partyList[fromParty].selectedCities.filter(item => item !== event.cityId);
      this.partyList[this.partyList.length - 1].selectedCities.push(city);
    } else {
      this.partyList[this.selectedPartyId].selectedCities.push(city);
      this.partyList[this.partyList.length - 1].selectedCities = this.partyList[this.partyList.length - 1].selectedCities.filter(item => item !== event.cityId);
    }
  }

  initParashikimiHartes(): void {
    this.paralamentareService.getAnalystPredictions().subscribe(response => {
      this.mapAnalyses = response;
    });
  }

  showOldAnalyse() {
    const analysis = this.mapAnalyses.filter(item => item.id === parseInt(this.oldAnalysis))[0];

    const data = JSON.parse(analysis.selectedCitites);

    let newPartyList = [];

    this.partyList.forEach(i => {
      i.selectedCities = data.cities[`${i.name}`];
      newPartyList.push(i);
    });

    this.partyList = newPartyList;

    this.analystName = analysis.name;

    this.newAnalysis = false;
    this.showMapAnalysis = true;
  }

  showNewAnalysis() {
    this.newAnalysis = true;
    this.showMapAnalysis = false;
    this.analystName = "";
    this.oldAnalysis = null;

    const tePaselektuaraAll = {
      name: 'PASELEKTUARA',
      color: 'white',
      value: false,
      percentage: 0,
      selectedCities: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36]
    };
    this.tePaselektuara = Object.assign({}, tePaselektuaraAll);
    this.loadData();
  }

  analysisAction() {
    if (this.newAnalysis) {
      this.saveMapPrediction();
    } else {
      this.showNewAnalysis();
    }
  }

  saveMapPrediction(): void {
    if (!this.analystName || this.analystName.trim().length === 0) {
      return;
    }

    this.newAnalysis = false;
    this.showMapAnalysis = true;

    let obj = {};
    let percentage = {};
    this.partyList.forEach(party => {
      obj[`${party.name}`] = party.selectedCities;
      percentage[`${party.name}`] = {
        percentage: party.percentage,
        color: party.color
      };
    });


    const cititesJson = JSON.stringify({ cities: obj });
    const percentageJson = JSON.stringify(percentage);
    const data = {
      Name: this.analystName,
      SelectedCitites: cititesJson,
      PartyPercentage: percentageJson
    };

    this.paralamentareService.saveAnalystPrediction(data).subscribe(_ => {
      this.initParashikimiHartes();
     });
  }
}
