import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { getBaseUrl } from '../../main';

@Injectable({
  providedIn: 'root',
})
export class ParalamentareService {

  baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = getBaseUrl();
  }

  saveAnalystPrediction(data) {

    return this.http.post(
      this.baseUrl + 'api/elections/analyst-prediction',
      JSON.stringify(data),
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );

  }

  saveChairsPrediction(data){
    return this.http.post(
      this.baseUrl + 'api/elections/chairs-prediction', JSON.stringify(data),
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  getPartyColors() {
    return this.http.get(this.baseUrl + 'api/elections/GetPartyColors');
  }

  getAnalystPredictions() {
    return this.http.get(this.baseUrl + 'api/elections/analyst-prediction');
  }

  getChairsPredictions() {
    return this.http.get(this.baseUrl + 'api/elections/chairs-prediction');
  }

  getAvailablePartiesToPredict() {
    return this.http.get(this.baseUrl + 'api/elections/GetAvailablePartiesToPredict');
  }

  loadGeneralData(year: number) {
    return this.http.get(this.baseUrl + `api/elections/GetGeneralStatistics/${year}`);
  }

  getPersonsWithRightToVote() {
    return this.http.get(this.baseUrl + `api/elections/GetPersonsWithRightToVote`);
  }

  getMostVotedParties(year: number) {
    return this.http.get(this.baseUrl + `api/elections/GetMostVotedParties/year/${year}`);
  }

  getLeaderData(leaderId: number) {
    return this.http.get(this.baseUrl + `api/elections/GetLeaderData/${leaderId}`);
  }

  getCoalitions() {
    return this.http.get(this.baseUrl + `api/elections/GetCoalitions`);
  }

  getPartiesToCompare(year: number, idCity: number) {
    return this.http.get(this.baseUrl + `api/elections/GetPartiesToCompare/year/${year}/idCitySvg/${idCity}`);
  }

  getLeadersToCompare(year: Number) {
    return this.http.get(this.baseUrl + `api/elections/GetLeadersToCompare/year/${year}`);
  }

  getPartyDataByYears(partyName: string, year1: number, year2: number, idCity: number) {
    return this.http.get(this.baseUrl + `api/elections/GetPartyDataByYears/${encodeURIComponent(partyName)}/year1/${year1}/year2/${year2}/idCitySvg/${idCity}`);
  }

  getGorvernmentByYears(year: number, idCity: number) {
    return this.http.get(this.baseUrl + `api/GovernmentAndParliment/GetGovernment/year/${year}/idCitySvg/${idCity}`);
  }

  getParlament(year: number, idCity: number) {
    return this.http.get(this.baseUrl + `api/GovernmentAndParliment/GetParlament/year/${year}/idCitySvg/${idCity}`);
  }

  // Add, Edit, Delete Government Member
  saveGovMember(data) {
    return this.http.post(`${this.baseUrl}api/GovernmentAndParliment/SaveGovMember`, JSON.stringify(data));
  }

  editGovMember(data) {
    return this.http.put(`${this.baseUrl}api/GovernmentAndParliment/EditGovMember`, JSON.stringify(data));
  }

  deleteGovMember(id: number) {
    return this.http.delete(`${this.baseUrl}api/GovernmentAndParliment/DeleteGovMember/${id}`);
  }

  // Add, Edit, Delete Parlament Member
  saveParlamentMember(data) {
    return this.http.post(`${this.baseUrl}api/GovernmentAndParliment/SaveParlamentMember`, JSON.stringify(data));
  }

  editParlamentMember(data) {
    return this.http.put(`${this.baseUrl}api/GovernmentAndParliment/EditParlamentMember`, JSON.stringify(data));
  }

  deleteParlamentMember(id: number) {
    return this.http.delete(`${this.baseUrl}api/GovernmentAndParliment/DeleteParlamentMember/${id}`);
  }

  // Coalitions
  saveCoalition(data) {
    return this.http.post(`${this.baseUrl}api/elections/SaveCoalition`, JSON.stringify(data));
  }

  editCoalition(data) {
    return this.http.put(`${this.baseUrl}api/elections/EditCoalition`, JSON.stringify(data));
  }

  deleteCoalition(id: number) {
    return this.http.delete(`${this.baseUrl}api/elections/DeleteCoalition/${id}`);
  }

  getElectionsYears() {
    return this.http.get(this.baseUrl + `api/elections/GetElectionsYears`);
  }
}
