<div class="row">
    <div class="col-sm-1"></div>
    <div class="col-sm-10">
        <div class="row displayContentCenter" *ngIf="!showCityMinistres">
            <div class="p-2" *ngFor="let year of electionsYears">
                <button type="button" [ngClass]="selectedYear === year ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'" (click)="loadData(year)">{{year}}</button>
              </div>
            <div class="p-2">
                <button
                    type="button"
                    [ngClass]="showMap ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'"
                    (click)="mapDeputies()"
                >HARTA E DEPUTETEVE</button>
            </div>
        </div>
        <ng-template [ngIf]="!showMap">
            <div class="d-flex flex-row displayContentCenter">
                <div class="row displayContentCenter" style="min-height: 500px;">
                    <div class="col-sm-8 align-self-center" style="min-width: 500px;">
                        <div class="p-2 align-self-center" style="height: 100%; width: 100%;">
                            <!-- <app-uleset-ne-parlament [year]="yearToLoad" [parties]="parties" ></app-uleset-ne-parlament> -->
                            <div class="d-flex flex-row displayContentCenter">
                                <div class="flourish-embed" data-src="/koalicionet/index.html">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row displayContentCenter">
                <div class="col-sm" >
                    <div
                        style="width: 450px; height: 40px; background-color: orangered;" >
                        <h3 style="padding-top: 8px; padding-left: 5px; color: white">DEPUTETET E LEGJISLATURES {{ electionsYears.indexOf(selectedYear) + 1 }}</h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2" style="margin-top: 20px !important;">
                    <div class="row" [hidden]="personView || !selectedParty" style="width: 90%;">
                        <button
                            type="button"
                            style="width: 100% !important; background-color: white; margin-top: 10px"
                            class="btn"
                            (click)="selectedParty = false; selectedPartyName = '';"
                        >Te Gjithe</button>
                    </div>
                    <div class="row" [hidden]="personView" style="width: 90%;" *ngFor="let party of parties; let i = index">
                        <button
                            type="button"
                            style="width: 100% !important; background-color: white; margin-top: 10px; overflow: hidden; border-radius: 20px 0 0 20px !important;"
                            [ngStyle]="{'background-color': (selectedPartyName === party ? 'rgba(255,255,0,0.2)' : 'white'), 'border-left': '20px solid ' + getPartyColor(party)}"
                            class="btn"
                            (click)="partyClicked(party)"
                        >{{ party }} <b class="ministers-number">{{ partiesMinisters[party] }}</b></button>
                    </div>
                </div>
                <div class="col-sm-10">
                    <div class="row" [hidden]="personView || selectedYear == 0 || selectedParty">
                        <div style="width: 19%" *ngFor="let m of ministers; let i = index;">
                            <div 
                                id="{{'leader-data-' + i}}" 
                                class="content-center"
                                style="background-color: white; width: 100%; height: 270px;">
                                <div 
                                    class="parlamenti-info center"
                                    (click)="personClicked(m)">
                                    <!-- <img class="avatar" style="width: 100%; height: auto;" [src]="'/images/parlamenti/'+m.profilePic" height="170" width="170" /> -->
                                    <br>
                                    <div style="height:110px; width:110px; border-radius: 50%; margin: auto"
                                        [ngStyle]="{'background-color': getPartyColor(m.party)}">
                                        <img class="avatar" style="border-radius: 50%; margin: 5px 5px 5px 5px" [src]="imgBaseUrl + '/images/parlamenti/' + m.profilePic" height="100" width="100" />
                                    </div>
                                    <h4 style="padding-top: 15px;">{{ m.name }}</h4>
                                    <h5 style="color: lightslategrey">{{ m.party }}</h5>
                                    <!-- <h6 style="padding-top: 5px"><a href="">Explore <i class="material-icons" style="font-size: 14px;">arrow_forward</i></a></h6> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="selectedParty" [hidden]="personView">
                        <div style="width: 20%" *ngFor="let m of filteredMinisters; let i = index;">
                            <div 
                                id="{{'leader-data-' + i}}" 
                                class="content-center"
                                style="background-color: white; width: 100%; height: 270px;">
                                <div 
                                    class="parlamenti-info"
                                    (click)="personClicked(m)">
                                    <!-- <img class="avatar" style="width: 100%; height: auto;" [src]="'/images/parlamenti/'+m.profilePic" height="170" width="170" /> -->
                                    <br>
                                    <div style="height:110px; width:110px; border-radius: 50%; margin: auto"
                                        [ngStyle]="{'background-color': getPartyColor(m.party)}">
                                        <img class="avatar" style="border-radius: 50%; margin: 5px 5px 5px 5px" [src]="imgBaseUrl + '/images/parlamenti/' + m.profilePic" height="100" width="100" />
                                    </div>
                                    <h4 style="padding-top: 15px;">{{ m.name }}</h4>
                                    <h5 style="color: lightslategrey">{{ m.party }}</h5>
                                    <!-- <h6 style="padding-top: 5px"><a href="">Explore <i class="material-icons" style="font-size: 14px;">arrow_forward</i></a></h6> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="height: 400px" *ngIf="personView">
                        <div class="row" style="background-color: white; width: 100%; min-height: 500px">
                            <div class="col-sm-4" style="text-align: center">
                                <h2 style="padding-top: 10px;">{{ selectedPerson.name }}</h2>
                                <br>
                                <img class="avatar" style="width: 100%; height: auto;" [src]="imgBaseUrl + '/images/parlamenti/'+selectedPerson.profilePic" />
                            </div>
                            <div class="col-sm-8" style="padding-left: 40px">
                                <div class="row" style="width: 100%; height: 80px;">
                                    <div class="p-2 close-modal-div" 
                                            style="border-radius: 50% !important; margin-left: auto; margin-right: 20px; margin-top: 20px; cursor: pointer;"
                                            (click)="personView = false;">
                                        <i class="material-icons"> close</i>
                                    </div>
                                </div>
                                <div class="row" style="width: 80%">
                                    <h5 style="margin-top: 10px; white-space: pre-line;">{{ selectedPerson.biography }}</h5>
                                </div>
                                <div class="row" style="margin-top: 200px">
                                    <div>
                                        <hr>
                                        <h4><i class="material-icons" style="font-size: 14px;">flag</i>  {{ selectedPerson.party }}</h4>
                                        <h4><i class="material-icons" style="font-size: 14px;">business</i>  {{ selectedPerson.cityOfBirth }}</h4>
                                        <!-- <h4><i class="material-icons" style="font-size: 14px;">call</i>  {{ selectedPerson.cityOfBirth }}</h4> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="selectedYear == 0">
                        <div style="border: 2px yellow solid; width: 100%;">
                            <app-uleset-ne-parlament  [year] = "years[0]" [parties] = "parties[0]" ></app-uleset-ne-parlament>
                        </div>
                    </div>
                </div>
                <div class="col-sm"></div>
            </div>
        </ng-template>
        <ng-template [ngIf]="showMap && !showCityMinistres">
            <div class="row displayContentCenter" style="margin: 0 0 15px 0 !important;">
                <div class="center" >
                    <div
                        style="height: 40px; background-color:#D16517;" >
                        <h3 style="padding: 8px 5px 0 5px; color: white">SHPERNDARJA E DEPUTETEVE NEPER KOMUNA</h3>
                    </div>
                </div>
            </div>
            <div class="row content-center">
                <app-kosovo-map 
                    [cityVotes]='cityVotes'
                    (cityClickHandeled)="cityClicked($event)" 
                    style="width: 65%" 
                    [clickMode]="2"
                    maxValue="20"
                    fillStatic="true">
                </app-kosovo-map>
            </div>
            <!-- <div class="row">
                <div class="col-sm-2"></div>
                <div class="col-sm-8" style="padding-top: 20px">
                    <div class="col-sm-12" style="height: 15px; background-image: linear-gradient(to right, #202020, white);"></div>
                    <div class="col-sm-12">
                        <div class="row">
                            <div style="margin-left:20px;"><h4><strong>0</strong></h4></div>
                            <div style="margin-left:auto; margin-right:20px;"><h4><strong>20</strong></h4></div>
                        </div>
                    </div>
                </div>
                <br><br>
            </div> -->
        </ng-template>
        <ng-template [ngIf]="showCityMinistres">
            <div class="row" style="padding-top: 20px">
                <div >
                  <button type="button"
                          class="btn btn-warning"
                          (click)="showCityMinistres = false; showMap = true;">
                    <i class="material-icons" style="font-size: 14px;">arrow_back</i>
                    {{ selectedCityName }}
                  </button>
                </div>
                <!-- <div style="padding-left: 10px" *ngFor="let year of years; let i = index">
                    <button
                        type="button"
                        [ngClass]="selectedYear === year ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'"
                        (click)="loadData(year)"
                    >{{ year }}</button>
                </div> -->
            </div>
            <div class="row">
                <div class="col-sm-8">
                    <div class="row" [hidden]="personView || selectedYear == 0 || selectedParty">
                        <div style="width: 20%" *ngFor="let m of ministers; let i = index;">
                            <div 
                                id="{{'leader-data-' + i}}" 
                                class="content-center"
                                style="background-color: white; width: 100%; height: 270px;">
                                <div 
                                    class="parlamenti-info center"
                                    (click)="personClicked(m)">
                                    <!-- <img class="avatar" style="width: 100%; height: auto;" [src]="'/images/parlamenti/'+m.profilePic" height="170" width="170" /> -->
                                    <br>
                                    <div style="height:110px; width:110px; border-radius: 50%; margin: auto"
                                        [ngStyle]="{'background-color': getPartyColor(m.party)}">
                                        <img class="avatar" style="border-radius: 50%; margin: 5px 5px 5px 5px" [src]="imgBaseUrl + '/images/parlamenti/' + m.profilePic" height="100" width="100" />
                                    </div>
                                    <h4 style="padding-top: 15px;">{{ m.name }}</h4>
                                    <h5 style="color: lightslategrey">{{ m.party }}</h5>
                                    <!-- <h6 style="padding-top: 5px"><a href="">Explore <i class="material-icons" style="font-size: 14px;">arrow_forward</i></a></h6> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="selectedParty" [hidden]="personView">
                        <div style="width: 20%" *ngFor="let m of filteredMinisters; let i = index;">
                            <div 
                                id="{{'leader-data-' + i}}" 
                                class="content-center"
                                style="background-color: white; width: 100%; height: 270px;">
                                <div 
                                    class="parlamenti-info"
                                    (click)="personClicked(m)">
                                    <img class="avatar" style="width: 100%; height: auto;" [src]="imgBaseUrl + '/images/parlamenti/'+m.profilePic" height="170" width="170" />
                                    <h4 style="padding-top: 15px;">{{ m.name }}</h4>
                                    <h5 style="color: lightslategrey">{{ m.party }}</h5>
                                    <!-- <h6 style="padding-top: 5px"><a href="">Explore <i class="material-icons" style="font-size: 14px;">arrow_forward</i></a></h6> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="height: 400px" *ngIf="personView">
                        <div class="row" style="background-color: white; width: 100%; min-height: 500px">
                            <div class="col-sm-4" style="text-align: center">
                                <h2 style="padding-top: 10px;">{{ selectedPerson.name }}</h2>
                                <br>
                                <img class="avatar" style="width: 100%; height: auto;" [src]="'/images/parlamenti/'+selectedPerson.profilePic" />
                            </div>
                            <div class="col-sm-8" style="padding-left: 40px">
                                <div class="row" style="width: 100%; height: 80px;">
                                    <div class="p-2 close-modal-div"
                                            style="border-radius: 50% !important; margin-left: auto; margin-right: 20px; margin-top: 20px; cursor: pointer;"
                                            (click)="personView = false;">
                                        <i class="material-icons"> close</i>
                                    </div>
                                </div>
                                <div class="row" style="width: 80%">
                                    <h5 style="margin-top: 10px; white-space: pre-line;">{{ selectedPerson.biography }}</h5>
                                </div>
                                <div class="row" style="margin-top: 100px">
                                    <div>
                                        <hr>
                                        <h4><i class="material-icons" style="font-size: 14px;">flag</i>  {{ selectedPerson.party }}</h4>
                                        <h4><i class="material-icons" style="font-size: 14px;">business</i>  {{ selectedPerson.cityOfBirth }}</h4>
                                        <!-- <h4><i class="material-icons" style="font-size: 14px;">call</i>  {{ selectedPerson.cityOfBirth }}</h4> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="selectedYear == 0">
                        <div style="border: 2px yellow solid; width: 100%;">
                            <app-uleset-ne-parlament  [year] = "years[0]" [parties] = "parties[0]" ></app-uleset-ne-parlament>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div style="padding-top: 50px">
                        <app-all-cities-map 
                            [cityId]="selectedCityId">
                        </app-all-cities-map>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
