<div class="lokale2021">
    <div class="d-flex flex-row header" *ngIf="selectedView !== 'Analizat'"> 
        <div class="col-sm-3">
            <div id="vlogo" (click)="backToMainView()">
                <div id="vlogo-color">
                    <object height="60vh" data="/icons/vlogo_icon.svg"></object>
                </div>
                <div id="vlogo-grey">
                    <object height="60vh" data="/icons/vlogo_icon_grey.svg"></object>
                </div>
            </div>
            <div *ngIf="selectedCity !== -1" class="p-2">
                <button type="button" class="btn btn-success active-btn btn-selected-city" (click)="backToMainView()">{{ selectedCityName }}</button>
            </div>
            <div *ngIf="selectedCity === -1" class="p-2 titleText">
                HISTORIKU I<br />ZGJEDHJEVE
            </div>
        </div>
        <div class="col-sm-6">
            <div class="p-2" *ngFor="let year of electionsYears">
                <button type="button" [ngClass]="selectedYear === year ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'" (click)="loadYear(year)">{{ year }}</button>
            </div>
        </div>
        <div *ngIf="selectedCity === -1" class="col-sm-3">
            <div class="p-2" *ngFor="let view of mainViews">
                <button type="button" [ngClass]="selectedView === view ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'" (click)="loadView(view)">{{ view }}</button>
            </div>
            <div class="p-2">
                <button type="button" [ngClass]="selectedView === 'Analizat' ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'" (click)="loadView('Analizat')"> Parashikimi </button>
            </div>
        </div>
        <div *ngIf="selectedCity !== -1" class="col-sm-3">
            <div class="p-2" *ngFor="let view of cityViews">
                <button type="button" [ngClass]="selectedCityView === view ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'" (click)="loadCityView(view)">{{ view }}</button>
            </div>
        </div>
    </div>

    <app-historiku-parlamentare 
        *ngIf="selectedView === 'Parlamentare' && selectedCity === -1"
        [loadYear]="selectedYear"
        (cityClickHandeled)="loadCity($event)"
    ></app-historiku-parlamentare>

    <app-historiku-lokale 
        *ngIf="selectedView === 'Lokale' && selectedCity === -1"
        [loadYear]="selectedYear"
        (cityClickHandeled)="loadCity($event)"
    ></app-historiku-lokale>

    <app-historiku-komuna 
        *ngIf="selectedCity !== -1"
        [selectedCityView]="selectedCityView"
        [selectedCity]="selectedCity"
        [loadYear]="selectedYear"
        [availableViews]="cityViews"
    ></app-historiku-komuna>

    <app-historiku-analizat 
        *ngIf="selectedView === 'Analizat'"
        [loadYear]="selectedYear"
        (backToMainView)="backToMainViewFromAnalyses()"
    ></app-historiku-analizat>

    <div class="footer">
        <div class="side">
            <div class="link-item">
                
                RTV Dukagjini
            </div>
        </div>
        <div class="middle">
            <div class="link-item">
                <object data="/icons/FB_icon.svg" height="12px"></object>
                dukagjinirtv
            </div>
            <div class="link-item">
                <object data="/icons/Insta_icon.svg" height="12px"></object>
                dukagjinirtv</div>
            <div class="link-item">
                <object data="/icons/YT_icon.svg" height="12px"></object>
                RTV Dukagjini</div>
            <div class="link-item">
                <object data="/icons/TW_icon.svg" height="12px"></object>
                RTV Dukagjini</div>
        </div>
        <div class="side">
            <div class="link-item">http://zgjedhjet.org/2021</div>
        </div>
    </div>
</div>