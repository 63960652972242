<div style="height:auto; padding-top: 20px;">
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" version="1.1" viewBox="0 0 3413 1559"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <metadata id="Parlament_Layer"/>
    <defs
      id="defs797">
    </defs>
    <g *ngFor="let item of circles; let i = index">
      <!-- <circle
        [attr.fill] = "item.style"
        r = "29"
        [attr.cy] = "item.cy"
        [attr.cx] = "item.cx"
        /> -->
        <path
        [attr.fill] = "item.style"
        stroke="#56534D" 
        stroke-width="30"
        [attr.d]="item.d"
        />
    </g>
  </svg>
  <div style="
      display: flex;
      align-items: center;
      justify-content: center;">
    <h1>{{ totalNumbers }}</h1>
  </div>
</div>
