import { Component, OnInit } from '@angular/core';
import { ParalamentareService } from './paralamentare.service';

@Component({
  selector: 'app-parlamentare',
  templateUrl: './parlamentare.component.html',
  styleUrls: ['./parlamentare.component.css']
})
export class ParlamentareComponent implements OnInit {

  activeComponent = 'statistika-te-pergjithshme';
  electionsYears: any[] = [];
  constructor(private _parlamentareService: ParalamentareService) { }

  ngOnInit() {
    this._parlamentareService.getElectionsYears().subscribe(response => this.electionsYears = (response as any).map(i => i.year));
  }

  changeActiveComponent(component: string): void {
    this.activeComponent = component;
  }
}
