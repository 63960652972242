import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { ParalamentareService } from '../paralamentare.service';
import { cities } from '../kosovo-map/kosovo-map';
import { imgBaseUrl } from '../parlamentare';

@Component({
  selector: 'app-parlamenti',
  templateUrl: './parlamenti.component.html',
  styleUrls: ['./parlamenti.component.css']
})
export class ParlamentiComponent implements OnInit, OnChanges, OnDestroy {

  @Input() electionsYears: any[] = [];

  personView = false;
  selectedYear: number = 2019;
  showMap = false;
  showCityMinistres = false;
  selectedCityId: number;
  parties = [];
  partiesMinisters = {};
  selectedParty = false;
  selectedPartyName = "";
  selectedPerson: any[] = [];
  selectedCityName: string;

  partiesParla: any[] = [];

  // showTableModal = false;

  cityVotes = [
    { votes: 0, name: 'Prishtina', id: 5 },
    { votes: 0, name: 'Prizren', id: 1 },
    { votes: 0, name: 'Ferizaj', id: 12 },
    { votes: 0, name: 'Pejë ', id: 2 },
    { votes: 0, name: 'Gjakovë', id: 3 },
    { votes: 0, name: 'Gjilan', id: 9 },
    { votes: 0, name: 'Mitrovicë', id: 15 },
    { votes: 0, name: 'Podujeva', id: 0 },
    { votes: 0, name: 'Vushtrri', id: 13 },
    { votes: 0, name: 'Suharekë', id: 11 },
    { votes: 0, name: 'Rahovec', id: 21 },
    { votes: 0, name: 'Drenas', id: 5 },
    { votes: 0, name: 'Lipjan', id: 16 },
    { votes: 0, name: 'Malishevë', id: 18 },
    { votes: 0, name: 'Kamenicë', id: 8 },
    { votes: 0, name: 'Viti', id: 22 },
    { votes: 0, name: 'Deçan', id: 19 },
    { votes: 0, name: 'Istog', id: 6 },
    { votes: 0, name: 'Klinë', id: 17 },
    { votes: 0, name: 'Skenderaj', id: 10 },
    { votes: 0, name: 'Dragash', id: 7 },
    { votes: 0, name: 'Fushë Kosovë', id: 31 },
    { votes: 0, name: 'Kaçanik', id: 24 },
    { votes: 0, name: 'Shtime', id: 26 },
    { votes: 0, name: 'Obiliq', id: 29 },
    { votes: 0, name: 'Leposaviq', id: 4 },
    { votes: 0, name: 'Graçanicë', id: 28 },
    { votes: 0, name: 'Han i Elezit', id: 30 },
    { votes: 0, name: 'Zveçan', id: 27 },
    { votes: 0, name: 'Shtërpcë', id: 23 },
    { votes: 0, name: 'Novobërdë', id: 25 },
    { votes: 0, name: 'Zubin Potok', id: 14 },
    { votes: 0, name: 'Junik', id: 33 },
    { votes: 0, name: 'Mamusha', id: 36 },
    { votes: 0, name: 'Ranillug', id: 32 },
    { votes: 0, name: 'Kllokoti', id: 35 },
    { votes: 0, name: 'Parteshi', id: 34 },
    { votes: 0, name: 'Gllogovc', id: 20 }
  ];

  ministers: any [] = [];
  filteredMinisters: any[] = [];
  imgBaseUrl = '';

  partyColors: any[] = [];

  constructor(private parlamentareService: ParalamentareService) {
    this.imgBaseUrl = imgBaseUrl();
  }

  ngOnInit() {
    this.loadScript();
    this.getDeputies();
  }

  ngOnDestroy() {
    this.removeScript();
  }

  ngOnChanges() {
    if (this.electionsYears.length > 0) {
      this.selectedYear = this.electionsYears[this.electionsYears.length - 1].year || 2019;
    }
  }

  getPartyColors() {
    this.parlamentareService.getPartyColors().subscribe(response => {
      const res = response as any;
      this.partyColors = res;
    });
  }

  getPartyColor(partyName: string){
    if (this.partyColors.length == 0) {
      return 'white';
    }

    const c = this.partyColors.filter(i => i.partyName == partyName);
    if(c.length > 0) {
      return c[0].color || 'white';
    }

    return 'white';
  }

  getDeputies(cityId = -1): void {
    this.selectedCityId = cityId;
    // selectedYear
    this.parlamentareService.getParlament(this.selectedYear, cityId).subscribe(response => {
      const res = response as any;
      this.ministers = res;
      this.filteredMinisters = res;
      this.parties = [];

      res.forEach(p => {
        if((this.parties.indexOf(p.party)) === -1) {
          this.parties.push(p.party);
          this.partiesMinisters[p.party] = this.ministers.filter(m => m.party == p.party).length;
        }
      });

      this.parties.sort(( a, b ) => {
        if ( this.partiesMinisters[a] < this.partiesMinisters[b] ){
          return 1;
        }
        if ( this.partiesMinisters[a] > this.partiesMinisters[b] ){
          return -1;
        }
        return 0;
      });
      //this.filterMinisters('PDK');

      this.getPartyColors();
    });
  }

  filterMinisters(party: string) {
    this.filteredMinisters = this.ministers.filter(p => p.party == party);
  }

  partyClicked(party: string) {
    this.filterMinisters(party);
    this.selectedPartyName = party;
    this.selectedParty = true;
  }

  personClicked(m: any): void {
    this.selectedPerson = m;
    this.selectedPerson['biography'] = m['biography'].replaceAll("w","\n");
    this.personView = true; 
  }

  cityClicked(event: any): void {
    this.selectedCityId = event.cityId;
    this.getDeputies(this.selectedCityId);
    this.showCityMinistres = true;
    const a  = this.cityVotes.filter(c => c.id === this.selectedCityId);

    this.selectedCityName = a ? a[0].name : '';
  }

  mapDeputies() {
    this.cityVotes.forEach(c => {
      c.votes = this.ministers.filter(p => p.idCitySvg == c.id).length;
    });
    this.showMap = true;
  }

  loadData(year: number): void {
    let flourish = document.querySelector(".flourish-embed iframe");
    if (flourish && flourish['contentWindow'] && flourish['contentWindow'].document.body) {
      var DeselectParty = flourish['contentWindow'].document.body.querySelector('div.viz-container');
      if (DeselectParty !== null) {
        DeselectParty.click();
      }
      let element = flourish['contentWindow'].document.body.querySelector('input[value="'+year+'"]');
      if (element !== null) {
        element.click();
      }
    } else {
      return;
    }

    this.showMap = false;
    this.personView = false;
    this.selectedYear = year;
    this.getDeputies();
  }

  createRange(number: number) {
    let items: number[] = [];
    for (let i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }

  removeScript() {
    document.getElementById("flourish.embed.js").remove();
    // document.querySelector("div.flourish-embed").remove();
  }

  loadScript() {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '/koalicionet/flourish.embed.js?cachebuster='+ new Date().getTime();
    script.id = 'flourish.embed.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
    let ctx = this;
    setTimeout(function() {
      ctx.loadData(2019);
      document.querySelector(".flourish-embed .flourish-credit")['style'].display = "none";
    }, 500);
  }
}
