<div class="d-flex flex-row lokale-full-screen">
    <div class="col-sm-5 subjektet-fituese-votat">
        <div class="zgjedhjet-caret">
            <div>
                ZGJEDHJET PARLAMENTARE {{ selectedYear }}
                <img class="zgjedhjet-caret-logo" width="100%" src="{{ imgBaseUrl + '/images/zgjedhjet-caret.png' }}"/>
            </div>
        </div>
        <div *ngFor="let party of mostVotedParties" class="subjekti">
            <div class="emri">{{party.name}}</div>
            <div class="votat">
                <div class="votat-pr" [ngStyle]="{'background-color': party.color, 'width': party.percentage}"></div>
                <div class="votat-nr" [ngStyle]="{'left': party.percentage}">{{party.nrOfVotes}}</div>
            </div>
        </div>
    </div>
    <div class="col-sm-5 center-items">
    <div style="width: 100%">
        <app-kosovo-map (cityClickHandeled)="cityClicked($event)" [winingParties]="winingParties" clickMode='-1'></app-kosovo-map>
    </div>
    </div>
    <div class="col-sm-2 subjektet-fituese-ngjyrat">
        <div *ngFor="let party of mostVotedParties" class="subjekti">
            <div class="ngjyra" [ngStyle]="{'background-color': party.color}"></div>
            <div class="ngjyra-emri">{{party.name}}</div>
        </div>
    </div>
</div>