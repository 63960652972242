import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ParalamentareService } from '../paralamentare.service';
import { PersonsWithRightToVote } from './statistika-te-pergjithshme';
import { imgBaseUrl } from '../parlamentare';

declare let google: any;

@Component({
  selector: 'app-statistika-te-pergjithshme',
  templateUrl: './statistika-te-pergjithshme.component.html',
  styleUrls: ['./statistika-te-pergjithshme.component.css']
})
export class StatistikaTePergjithshmeComponent implements OnInit {

  @Output() goToPjesmarrja: EventEmitter<any> = new EventEmitter<any>();

  @Input() electionsYears: any[] = [];

  generalData: any;
  personsWithRightToVote: PersonsWithRightToVote;
  personsWithRightToVoteList: PersonsWithRightToVote[] = [];
  mostVotedParties: any[] = [];
  mostVotedPersons: any[] = [];
  mostVotedPersonsToShow: any[] = [];
  participation: any;
  showAllContent = true;

  mostVotedPartiesChart: any;

  selectedYear = 2019;

  totalPopulation = 2000000;

  participationByYear: any[] = [];

  calculatedPercentage = 0;

  showLeaderContent = false;
  selectedLeader: number;

  constructor(private _paralamentareService: ParalamentareService) {
    google.charts.load('current', { 'packages': ['corechart', 'table'] });
    google.charts.setOnLoadCallback();

    this.personsWithRightToVote = new PersonsWithRightToVote();
  }

  ngOnInit() {
    this.loadData(this.selectedYear);
  }

  loadData(year: number): void {
    this.selectedYear = year;

    this._paralamentareService.loadGeneralData(year).subscribe(response => {
      const res = response as any;
      this.mostVotedParties = res.mostVotedParties;

      res.mostVotedPersons.forEach(i => {
        i.profilePic = imgBaseUrl() + '/images/' + i.profilePic;
        return i;
      });

      this.mostVotedPersons = res.mostVotedPersons;
      this.participation = res.participation;
      this.personsWithRightToVoteList = [];
      for (let i = res.personsWithRightToVote.length; i > 0; i--) {
        this.personsWithRightToVoteList.push(res.personsWithRightToVote[i-1]);
      }
      this.personsWithRightToVote = res.personsWithRightToVote.filter(i => i.year === year)[0];
      this.calculatePercentage(this.personsWithRightToVote.nrOfPersons);


      setTimeout(() => {
        this.drawMostVotedPersonsPercentages();
        this.drawMostVotedParties();
        this.drawParticipation();
      }, 1000);

    }, error => {
      console.log(error);
    });
  }

  getPersonsWithRightToVote(): void {
    this.showAllContent = false;

    this._paralamentareService.getPersonsWithRightToVote().subscribe(response => {
      this.personsWithRightToVote = response as any;
    }, error => {
      console.log(error);
    });
  }

  calculatePercentage(nrOfPersons: number): void {
    const percentage = (nrOfPersons * (100 / this.totalPopulation)) / 100;
    this.calculatedPercentage = parseFloat(percentage.toFixed(2));
  }

  numberWithCommas(x: number): String {
    return x.toLocaleString();
  }

  refreshCharts(): void {
    setTimeout(() => {
      this.drawMostVotedParties();
      this.drawParticipation();
      this.drawMostVotedPersonsPercentages();
    }, 1000);
  }

  drawMostVotedPersonsPercentages(): void {

    let totalVotes = 0;

    this.mostVotedPersons.forEach(person => {
      totalVotes += person.votes;
    });

    const options = {
      titlePosition: '',
      titleTextStyle: {
        color: '#FFFFFF',
        fontSize: 15,
        bold: true
      },
      legend: { position: 'none' },
      pieHole: 0.87,
      width: 155,
      height: 155,
      backgroundColor: 'transparent',
      colors: ['#5ABF85', '#cfe3d7'],
      pieSliceBorderColor: 'transparent',
      pieSliceText: 'none',
      enableInteractivity: false,
      chartArea: {
        width: "100%" ,
        height: "100%" ,
      },
    };


    for (let i = 0; i < this.mostVotedPersons.length; i++) {
      const person = this.mostVotedPersons[i];

      person.percentage = person.votes * (100 / totalVotes);
      person.remainingPercentage = 100 - person.percentage;

      const data = new google.visualization.arrayToDataTable([
        ['Votat', 'Totali'],
        ['Votat', person.percentage],
        ['Totali', person.remainingPercentage]
      ]);
      const chart = new google.visualization.PieChart(document.getElementById(`leader-${i}`));
      chart.draw(data, options);
      document.getElementById(`leader-data-${i}`).style.visibility = 'visible';
    }
  }

  async drawMostVotedParties() {
    if (this.mostVotedParties.length === 0) {
      return;
    }

    let data = new google.visualization.DataTable();

    data.addColumn('string', 'Party');
    data.addColumn('number', 'Numri i votave');
    data.addColumn({ type: 'string', role: 'style' });
    data.addColumn({ type: 'string', role: 'annotation' });

    for (let i = 0; i < this.mostVotedParties.length; i++) {
      const obj = this.mostVotedParties[i];
      data.addRow([obj.name, parseInt(obj.nrOfVotes.toString(), 10), obj.color, obj.nrOfVotes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")]);
    }

    const options = {
      legend: 'none',
      backgroundColor: {
        fill: 'transparent'
      },
      width: document.getElementById('charts-container').clientWidth/3,
      height: 280,
      legendTextStyle: {
        bold: true
      },
      hAxis: {
        textStyle:{color: '#7D7D7D', bold: true, fontSize: 15,}
      },
      vAxis: {
        textStyle:{color: '#7D7D7D', bold: true, fontSize: 15,}
      },
      chartArea: {
        height: '70%',
        width: '70%'
      },
      animation: {
        startup: true,
        duration: 1500,
        easing: 'out',
      },
      annotations: {
        // alwaysOutside: true,
        textStyle: {
            fontSize: 20,
            color: '#7D7D7D',
            bold: true
        }
      },
    };
    await this.sleep(500);

    const chart = new google.visualization.BarChart(document.getElementById('subjektet-fituese'));

    chart.draw(data, options);
  }

  drawParticipation(): void {

    if (!this.participation) {
      return;
    }

    let data = new google.visualization.DataTable();

    data.addColumn('string', 'Votues');
    data.addColumn('number', 'Perqindja');
    data.addColumn({ type: 'string', role: 'style' });
    data.addColumn({ type: 'string', role: 'annotation' });
    data.addRow(['Votues', this.participation.voterPercentage, '#F2B20F', ('' + this.participation.voterPercentage)]);
    data.addRow(['JoVotues', (100 - this.participation.voterPercentage), '#c9c5c5', ('' + (100 - this.participation.voterPercentage))]);

    const options = {
      legend: 'none',
      backgroundColor: {
        fill: 'transparent'
      },
      width: document.getElementById('charts-container').clientWidth/3,
      height: 150,
      legendTextStyle: {
        bold: true
      },
      hAxis: {
        minValue: 0,
        maxValue: 100,
        textStyle: {color: '#7D7D7D', bold: true, fontSize: 15,}
      },
      vAxis: {
        textStyle: {color: '#7D7D7D', bold: true, fontSize: 15,}
      },
      chartArea: {
        height: '60%',
        width: '60%'
      },
      animation: {
        startup: true,
        duration: 1500,
        easing: 'out',
      },
      annotations: {
        // alwaysOutside: true,
        textStyle: {
            fontSize: 20,
            color: '#7D7D7D',
            bold: true
        }
      },
    };

    const chart = new google.visualization.BarChart(document.getElementById('pjesemarrja'));
    chart.draw(data, options);
  }

  selectMostVotedPerson(leaderId: number): void {

    this.selectedLeader = leaderId;

    this.showLeaderContent = true;
    this.showAllContent = false;

  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  goToPjesemarrja(): void {
    this.goToPjesmarrja.emit(true);
  }
}
