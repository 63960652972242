import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { store } from './kosovo-map.state';

@Component({
  selector: 'app-kosovo-map',
  templateUrl: './kosovo-map.component.html',
  styleUrls: ['./kosovo-map.component.css']
})
export class KosovoMapComponent implements OnInit, OnChanges {
  @Input() cityVotes: any = [];
  //@Input() cityId: number;
  @Input() cityFillColor = '';
  @Output() cityClickHandeled: EventEmitter<any> = new EventEmitter();
  @Input() clickMode = 0;
  @Input() clickable = true;
  @Input() hover = true;
  @Input() maxValue: number;
  @Input() winingParties: any = [];
  @Input() cityFillByAnalist: any[] = [];
  @Input() fillStatic = false;

  stock_fill = 'rgb(200, 200, 0)';
  fill = 'transparent'; // 'rgb(200, 200, 0)';
  fill_high = 'rgb(220, 220, 0)';
  sele = 'rgb(0, 200, 0)';
  sele_high = 'rgb(0, 220, 0)';
  selected = false;
  city: any[] = [];
  canHover = true;
  canChangeColor = true;


  constructor() { }

  ngOnInit() {
    if(this.cityVotes.length != 0) {
      this.percentageFill();
    } else if(this.winingParties.length != 0) {
      this.partyWinnerFill();
    } else if (this.cityFillByAnalist.length != 0) {
      this.analistFill();
    } else if (this.fillStatic){
      for (let i = 0; i <= 36; i++) {
        this.city[i] = [this.static_city_colors[i], this.selected];
      }
    } else {
      for (let i = 0; i <= 36; i++) {
        this.city[i] = [this.fill, this.selected];
      }
    }
    this.canHover = this.hover && (this.clickMode == 0 || this.clickMode == 1 ? true : false);
    this.canChangeColor = this.clickable && (this.clickMode == 0 || this.clickMode == 2 ? true : false);

    if (this.cityFillByAnalist) {
      this.showCityNames();
    }
  }

  ngOnChanges() {
    if(this.cityVotes.length != 0) {
      this.percentageFill();
    } else if(this.winingParties.length != 0) {
      this.partyWinnerFill();
    } else if (this.cityFillByAnalist.length != 0) {
      this.analistFill();
    }

    if (this.cityFillByAnalist) {
      this.showCityNames();
    }
  }

  analistFill() {
    this.cityFillByAnalist.forEach(a => {
      a.selectedCities.forEach(c => {
        this.city[c] = [a.color];
      });
    });
  }

  changeColor(n: number) {
    const data = {
      cityId: n
    };
    this.cityClickHandeled.emit(data);
    if(this.canChangeColor) {
      if(this.cityFillColor !== '') {
        this.city[n][0] = !this.city[n][1] ? this.cityFillColor : this.fill;
      } else {
        this.city[n][0] = !this.city[n][1] ? this.sele : this.fill;
      }
      this.city[n][1] = !this.city[n][1];
    }
  }

  changeStyle($event: any, n: number) {
    if(!this.canHover)
      return;
    // this.city[n][0] = $event.type == 'mouseover' ? 
    //                   this.city[n][1] ? this.sele_high : this.fill_high : this.city[n][1] ? this.sele : this.fill;
    $event.type == 'mouseover' ?
     document.getElementById("city"+n+"_"+n).style.opacity = "1" : document.getElementById("city"+n+"_"+n).style.opacity = "0";
  }
  
  fillColor(n: number) {
    let calcPerc = 80 + Math.round((n/this.maxValue) * 160);
    return 'rgb('+calcPerc+','+calcPerc+','+calcPerc+')';
  }

  percentageFill(): void {
    if (this.cityVotes.length != 0) {

      this.cityVotes.map(m => {
        
        //this.city[m.id] = [this.fillColor(m.votes), this.selected];
        this.city[m.id] = [this.static_city_colors[m.id], this.selected];
        let t = document.getElementById("city" + m.id);
        document.getElementById("city"+m.id+"_"+m.id).style.opacity = "1";
        //let shift = parseInt(t.getAttribute('x')+700);
        //t.setAttribute('x', '' + shift);
        t.innerHTML = '' + m.votes;
        t.style.opacity = '1';

      });
      this.canHover = false;
    } else {
      for (let i = 0; i <= 36; i++) {
        this.city[i] = [this.fill, this.selected];
      }
    }

    // store.state.subscribe(obj => {


    // });
  }

  partyWinnerFill() {
    this.winingParties.forEach(p => {
      this.city[p.idCitySvg] = [p.color];
      document.getElementById("city"+p.idCitySvg+"_"+p.idCitySvg).style.opacity = "1";
    });
  }

  clearMap() {
    for (let i = 0; i <= 36; i++) {
      document.getElementById(`city${i}`).style.opacity = '0';
      document.getElementById(`city${i}_${i}`).style.opacity = '0';
    }
  }

  showCityNames() {
    for (let i = 0; i <= 36; i++) {
      document.getElementById(`city${i}_${i}`).style.opacity = '1';
    }
  }

  static_city_colors = [
    "#AEDC3A",
    "#E0B469",
    "#AE498B",
    "#4A6191",
    "#6C93CF",
    "#FF222D",
    "#AEDC3A",
    "#FF8928",
    "#009CE7",
    "#FD8746",
    "#6E91D3",
    "#6495D7",
    "#01AD54",
    "#92E6FF",
    "#0DB7FD",
    "#50AB99",
    "#FF158F",
    "#FE8827",
    "#01AD53",
    "#FE1F56",
    "#92E6FF",
    "#FF1F54",
    "#04B5FE",
    "#009EE9",
    "#5B594A",
    "#3C3C3C",
    "#86726F",
    "#403E3A",
    "#3734A2",
    "#414143",
    "#FD2120",
    "#5EA87B",
    "#3A3997",
    "#00B3F7",
    "#33339B",
    "#443E45",
    "#424143",
  ]
}
