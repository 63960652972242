import { Component, OnInit, Input, OnChanges} from '@angular/core';
import { ParalamentareService } from '../paralamentare.service';
import { imgBaseUrl } from '../parlamentare';

@Component({
  selector: 'app-qeverite',
  templateUrl: './qeverite.component.html',
  styleUrls: ['./qeverite.component.css']
})
export class QeveriteComponent implements OnInit, OnChanges {

  @Input() electionsYears: any[] = [];

  selectedYear: number = 2020;
  showMap = false;
  selectedCityId = -1;
  showCityMinistres = false;
  personView = false;

  selectedPerson = [];

  imgBaseUrl = '';

  cityVotes = [
    { votes: 0, name: 'Prishtina', id: 5 },
    { votes: 0, name: 'Prizren', id: 1 },
    { votes: 0, name: 'Ferizaj', id: 12 },
    { votes: 0, name: 'Pejë ', id: 2 },
    { votes: 0, name: 'Gjakovë', id: 3 },
    { votes: 0, name: 'Gjilan', id: 9 },
    { votes: 0, name: 'Mitrovicë', id: 15 },
    { votes: 0, name: 'Podujeva', id: 0 },
    { votes: 0, name: 'Vushtrri', id: 13 },
    { votes: 0, name: 'Suharekë', id: 11 },
    { votes: 0, name: 'Rahovec', id: 21 },
    { votes: 0, name: 'Drenas', id: 5 },
    { votes: 0, name: 'Lipjan', id: 16 },
    { votes: 0, name: 'Malishevë', id: 18 },
    { votes: 0, name: 'Kamenicë', id: 8 },
    { votes: 0, name: 'Viti', id: 22 },
    { votes: 0, name: 'Deçan', id: 19 },
    { votes: 0, name: 'Istog', id: 6 },
    { votes: 0, name: 'Klinë', id: 17 },
    { votes: 0, name: 'Skenderaj', id: 10 },
    { votes: 0, name: 'Dragash', id: 7 },
    { votes: 0, name: 'Fushë Kosovë', id: 31 },
    { votes: 0, name: 'Kaçanik', id: 24 },
    { votes: 0, name: 'Shtime', id: 26 },
    { votes: 0, name: 'Obiliq', id: 29 },
    { votes: 0, name: 'Leposaviq', id: 4 },
    { votes: 0, name: 'Graçanicë', id: 28 },
    { votes: 0, name: 'Han i Elezit', id: 30 },
    { votes: 0, name: 'Zveçan', id: 27 },
    { votes: 0, name: 'Shtërpcë', id: 23 },
    { votes: 0, name: 'Novobërdë', id: 25 },
    { votes: 0, name: 'Zubin Potok', id: 14 },
    { votes: 0, name: 'Junik', id: 33 },
    { votes: 0, name: 'Mamusha', id: 36 },
    { votes: 0, name: 'Ranillug', id: 32 },
    { votes: 0, name: 'Kllokoti', id: 35 },
    { votes: 0, name: 'Parteshi', id: 34 },
    { votes: 0, name: 'Gllogovc', id: 20 }
  ];

  ministers: any[];
  
  selectedCityName: string;
  constructor(private _paralamentareService: ParalamentareService) { 

    this.imgBaseUrl = imgBaseUrl();
  }

  ngOnInit() {
    this.loadData(this.selectedYear, -1);
  }

  ngOnChanges() {
    if (this.electionsYears.length > 0) {
      this.selectedYear = this.electionsYears[this.electionsYears.length - 1].year || 2020;
    }
  }

  loadData(year: number, cityId: number): void {
    //this.showMap = false;
    this.selectedYear = year;
    this.selectedCityId = cityId;
    this.showCityMinistres = false;
    this.personView = false;

    this._paralamentareService.getGorvernmentByYears(year, cityId).subscribe(response => {
      const res = response as any;
      this.ministers = res;

      

    }, _error => {
      // alert('An error ocurred!');
    });
  }


    mapDeputies() {
      this.cityVotes.forEach(c => {
        c.votes = this.ministers.filter(p => p.idCitySvg == c.id).length;
      });
      this.showMap = true;
    }

  personClicked(m: any): void {
    this.selectedPerson = m;
    this.selectedPerson['biography'] = m['biography'] ? m['biography'].replaceAll("w","\n") : "";
    this.personView = true;
  }

  cityClicked(event: any): void {
    this.selectedCityId = event.cityId;
    this.loadData(this.selectedYear, this.selectedCityId);
    this.showCityMinistres = true;
    const a = this.cityVotes.filter(c => c.id === this.selectedCityId);

    this.selectedCityName = a ? a[0].name : '';
  }

  //getDeputies(cityId = -1): void {
  //  this.selectedCityId = cityId;
  //  // selectedYear
  //  this.parlamentareService.getParliment(this.selectedYear, cityId).subscribe(response => {
  //    const res = response as any;
  //    this.ministers = res;
  //    this.filteredMinisters = res;
  //    this.parties = [];

  //    res.forEach(p => {
  //      if ((this.parties.indexOf(p.party)) === -1) {
  //        this.parties.push(p.party);
  //      }
  //    });
  //    //this.filterMinisters('PDK');
  //  });
  //}

  createRange(number: number){
    let items: number[] = [];
    for(let i = 1; i <= number; i++){
       items.push(i);
    }
    return items;
  }
}
