import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { HistorikuLokaleService } from './lokale.service';
import { LocaleService } from '../../locale.service';
import { imgBaseUrl } from '../../../parlamentare/parlamentare';

declare let google: any;

@Component({
  selector: 'app-historiku-lokale',
  templateUrl: './lokale.component.html',
  styleUrls: ['./lokale.component.css','../historiku.component.css']
})
export class HistorikuLokaleComponent implements OnInit, OnChanges {

  @Input() loadYear: number = 2021;
  @Output() cityClickHandeled: EventEmitter<any> = new EventEmitter();

  selectedYear: number = this.loadYear;
  showMap = false;
  mostVotedParties: any[] = [];
  mostMunicipalitiesCount: any[] = [];

  showPartyVotes = false;
  selectedCityId: number;

  showTableModal = false;
  tableModalPartyVotes: any[] = [];
  tableModalSelectedYear: number;

  selectedCityName: string;
  winingParties: any[] = [];
  citiesToIgnore: number[] = [];

  imgBaseUrl = "";

  constructor(private service: HistorikuLokaleService, private service2: LocaleService) {
    this.imgBaseUrl = imgBaseUrl();
    // google.charts.load('current', { 'packages': ['corechart', 'table'] });
    // google.charts.setOnLoadCallback();
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnChanges() {
    if (this.loadYear !== this.selectedYear) {
      this.loadData();
    }
  }

  cityClicked(data: any) {
    if (this.citiesToIgnore.indexOf(data.cityId) !== -1) return;
    this.cityClickHandeled.emit(data);
  }

  loadData(): void {
    this.showMap = false;
    this.selectedYear = this.loadYear;
    if (this.selectedYear === 2021) return;
    this.getMostVotedParties();
    this.getPartyCities();
    // this.getCityWiningPartiesByYear();
  }

  getPartyCities(): void {
    this.service2.getLocaleResultsForCities(this.selectedYear).subscribe(response => {
      if (!response["localeResultsForCities"]) {
        response["localeResultsForCities"] = [];
      };
      if (!response["citiesToIgnore"]) {
        response["citiesToIgnore"] = [];
      };
      this.citiesToIgnore = response["citiesToIgnore"];
      const res = response["localeResultsForCities"]
      let partyMunicipalitiesCount: any = {};

      res.forEach(c => {
        if (partyMunicipalitiesCount[c.party]) {
          partyMunicipalitiesCount[c.party] = {
            ...partyMunicipalitiesCount[c.party],
            municipalities: partyMunicipalitiesCount[c.party].municipalities + 1
          };
        } else {
          partyMunicipalitiesCount[c.party] = {
            name: c.party,
            color: c.partyColor,
            municipalities: 1
          }
        }
      });

      this.mostMunicipalitiesCount = Object
        .keys(partyMunicipalitiesCount)
        .map(key => partyMunicipalitiesCount[key])
        .sort((a, b) => b.municipalities - a.municipalities);


      this.winingParties = res.map(r => ({
        color: r.partyColor,
        idCitySvg: r.cityId,
        partyName: r.party,
      }));

      this.citiesToIgnore.forEach(cityId => {
        this.winingParties.push({
          color: "#b1b7bc",
          idCitySvg: cityId,
          partyName: "Komunitetet",
        });
      });
    });
  }

  getMostVotedParties(): void {
    this.service.getMostVotedPartiesElectiveGeo(this.selectedYear).subscribe(response => {
      const res = response as any;
      const sorted = res.sort((a, b) => b.nrOfVotes - a.nrOfVotes);
      let sumOfVotes = 0;

      sorted.forEach(p => sumOfVotes += p.nrOfVotes);
      const normalizePercentage = 75 / ((sorted[0].nrOfVotes/sumOfVotes) * 75);
      //75 is the max value for the graph.

      this.mostVotedParties = sorted.map(p => ({
        ...p,
        percentage: (((p.nrOfVotes/sumOfVotes) * 75) * normalizePercentage) + "%"
      }));

      this.drawMostVotedParties();
    });
  }

  getCityWiningPartiesByYear(): void {
    this.service.getCityWiningPartiesByYear(this.selectedYear).subscribe(response => {
      const res = response as any;
      return;
      let partyMunicipalitiesCount: any = {};

      res.forEach(c => {
        if (partyMunicipalitiesCount[c.partyName]) {
          partyMunicipalitiesCount[c.partyName] = {
            ...partyMunicipalitiesCount[c.partyName],
            municipalities: partyMunicipalitiesCount[c.partyName].municipalities + 1
          };
        } else {
          partyMunicipalitiesCount[c.partyName] = {
            name: c.partyName,
            color: c.color,
            municipalities: 1
          }
        }
      });

      this.mostMunicipalitiesCount = Object
        .keys(partyMunicipalitiesCount)
        .map(key => partyMunicipalitiesCount[key])
        .sort((a, b) => b.municipalities - a.municipalities);

      this.winingParties = res;
      this.showMap = true;
    });
  }

  drawMostVotedParties() {

    if (this.mostVotedParties.length === 0) {
      return;
    }

  }

  openDialog(year: number): void {
    this.showTableModal = true;
    this.tableModalSelectedYear = year;
    this.tableModalPartyVotes = this[`partyVotes${year}`];
  }

  closePartyVotes(): void {
    this.showPartyVotes = false;
      setTimeout(() => {
        this.drawMostVotedParties();
      }, 1000);
  }

  nrOfCircles(nr: any, half: boolean = false, halfWithMod: boolean = false): number[] {
    let arr: number[] = [];
    let to: number = parseInt(nr);
    if (half && halfWithMod) {
      to = Math.floor((to / 2) + (to % 2));
    } else if (half) {
      to = Math.floor(to / 2);
    }
    for (let i=0; i<to; i++) {
      arr.push(i);
    }
    return arr;
  }
}
