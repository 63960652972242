import { Component, OnInit } from '@angular/core';
import { PjesemarrjaService } from './pjesemarrja.service';

declare let google: any;

@Component({
  selector: 'app-pjesemarrja',
  templateUrl: './pjesemarrja.component.html',
  styleUrls: ['./pjesemarrja.component.css']
})
export class PjesemarrjaComponent implements OnInit {

  participationStatistics: any[] = [];
  participationTime: number = 1900;

  constructor(private pjesemarrjaService: PjesemarrjaService) {
    google.charts.load('current', { 'packages': ['corechart', 'table'] });
  }

  ngOnInit() {
    this.loadData();
  }

  loadData(): void {
    this.pjesemarrjaService.getParticipationStatistics().subscribe(response => {
      const res = response as any;
      this.participationStatistics = res;
      // this.drawElParticipation();
      this.drawElParticipationByTime(this.participationTime);
    });
  }

  calPer(item: number, total: number): string {
    if (item === 0) {
      return '';
    }

    return ((item / total) * 100).toFixed(1) + '%';
  }

  createPercentage(until: number): string {
    return '';
  }

  showParticipationPerTime(time: number) {
    this.participationTime = time;
    this.drawElParticipationByTime(this.participationTime);
  }

  private drawElParticipationByTime(time: number): void {
    let arrayWithData = [];
    let timeString = (time / 100) + ":00";

    arrayWithData.push(['Pjesmarrja', 'Deri në ora ' + timeString, {role: 'annotation'}, { role: 'style' }]);

    this.participationStatistics.sort(( a, b ) => {
      if ( a.year < b.year ){
        return 1;
      }
      if ( a.year > b.year ){
        return -1;
      }
      return 0;
    });

    this.participationStatistics.forEach(item => {
      let itemUntil = item.until11 + item.until15 + item.until19;

      if (time === 1100) {
        itemUntil = item.until11;
      } else if (time === 1500) {
        itemUntil = item.until11 + item.until15;
      }

      arrayWithData.push([
        item.year.toString(),
        itemUntil,
        `${itemUntil.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (${this.calPer(itemUntil, item.totalWhoCanVote)})`,
        '#57bdf7',
      ]);

      // if (item.year <= 2007) {
      //   arrayWithData.push([
      //     item.year.toString(),
      //     itemUntil,
      //     `${this.calPer(itemUntil, item.totalWhoCanVote)} - ${itemUntil}`,
      //     '#57bdf7',
      //   ]);
      // } else {
        
      // }
    });

    const data = google.visualization.arrayToDataTable(arrayWithData);

    const options = {
      legend: 'none',
      backgroundColor: {
        fill: 'transparent'
      },
      height: 600,
      width: '100%',
      chartArea: {
        height: '75%',
        width: '80%'
      },
      hAxis: {
        textStyle:{color: 'black', bold: true, fontSize: 20}
      },
      vAxis: {
        textStyle:{color: 'black', bold: true, fontSize: 20}
      },
      legendTextStyle: {
        bold: true
      },
      animation: {
        startup: true,
        duration: 1500,
        easing: 'out',
      },
      annotations: {
        // alwaysOutside: true,
        textStyle: {
            fontSize: 20,
            color: 'white',
            bold: true
        },
        color: 'white'
      },
      isStacked: true,
    };

    const chart = new google.visualization.BarChart(document.getElementById('pjesemarrja'+time));
    chart.draw(data, options);
  }

  private drawElParticipation(): void {
    // const data = google.visualization.arrayToDataTable([
    //   ['Pjesmarrja', 'Deri në ora 11:00', {role: 'annotation'}, 'Prej 11:00 deri në ora 15:00', {role: 'annotation'}, 'Prej 15:00 deri në ora 19:00', {role: 'annotation'}, { role: 'style' }, { role: 'style' }, { role: 'style' }],
    //   ['2001',      0, `${this.calPer(     0, 1249987)}`,      0, `${this.calPer(0, 1249987)}`, 803796, `19:00 - 803796 (${this.calPer(803796, 1249987)})`, '#57bdf7', '#57bdf7', '#57bdf7' ],
    //   ['2004',      0, `${this.calPer(     0, 1412680)}`,      0, `${this.calPer(     0, 1412680)}`, 699519, `19:00 - 699519 (${this.calPer(699519, 1412680)})`, '#57bdf7', '#57bdf7', '#57bdf7' ],
    //   ['2007',      0, `${this.calPer(     0, 1567690)}`,      0, `${this.calPer(     0, 1567690)}`, 628630, `19:00 - 628630 (${this.calPer(628630, 1567690)})`, '#57bdf7', '#57bdf7', '#57bdf7' ],
    //   ['2010', 181121, `11:00 - 181121(${this.calPer(181121, 1632276)})`, 375485, `15:00 - ${(181121 + 375485)}(${this.calPer((181121 + 375485), 1632276)})`, 149711, `19:00 - 739318 (${this.calPer(181121 + 375485 + 149711 + 33001, 1632276)})`, '#57bdf7', '#57bdf7', '#57bdf7' ],
    //   ['2014', 168616, `11:00 - 168616(${this.calPer(168616, 1799023)})`, 283374, `15:00 - ${(168616 + 283374)}(${this.calPer((168616 + 283374), 1799023)})`, 282065, `19:00 - 766834‬ (${this.calPer(168616 + 283374 + 282065 + 32779, 1799023)})`, '#57bdf7', '#57bdf7', '#57bdf7' ],
    //   ['2017', 150244, `11:00 - 150244(${this.calPer(150244, 1888059)})`, 346877, `15:00 - ${(150244 + 346877)}(${this.calPer((150244 + 346877), 1888059)})`, 282608, `19:00 - 779729 (${this.calPer(150244 + 346877 + 282608, 1888059)})`, '#57bdf7', '#57bdf7', '#57bdf7' ],
    //   ['2019', 187554, `11:00 - 187554(${this.calPer(187554, 1937868)})`, (566631 - 187554), `15:00 - 566631(${this.calPer(566631, 1937868)})`, (851242 - 566631), `19:00 - 851242(44,1%)`, '#57bdf7','#57bdf7', '#57bdf7']
    // ]);

    let arrayWithData = [];

    arrayWithData.push(['Pjesmarrja', 'Deri në ora 11:00', {role: 'annotation'}, 'Prej 11:00 deri në ora 15:00', {role: 'annotation'}, 'Prej 15:00 deri në ora 19:00', {role: 'annotation'}, { role: 'style' }, { role: 'style' }, { role: 'style' }]);

    this.participationStatistics.forEach(item => {
      if (item.year <= 2007) {
        arrayWithData.push([
          item.year.toString(),
          item.until11,
          `${this.calPer(item.until11, item.totalWhoCanVote)}`,
          item.until15,
          `${this.calPer(item.until15, item.totalWhoCanVote)}`,
          item.until19,
          `19:00 - ${item.until19.toLocaleString()}(${this.calPer(item.until19, item.totalWhoCanVote)})`,
          '#57bdf7',
          '#57bdf7',
          '#57bdf7'
        ]);
      } else {
        arrayWithData.push([
          item.year.toString(),
          item.until11,
          `11:00 - ${item.until11.toLocaleString()}(${this.calPer(item.until11, item.totalWhoCanVote)})`,
          item.until15,
          `15:00 - ${(item.until15 + item.until11).toLocaleString()}(${this.calPer((item.until15 + item.until11), item.totalWhoCanVote)})`,
          item.until19,
          `19:00 - ${(item.until19 + item.until15 + item.until11).toLocaleString()}(${this.calPer((item.until19 + item.until15 + item.until11), item.totalWhoCanVote)})`,
          '#57bdf7',
          '#57bdf7',
          '#57bdf7'
        ]);
      }
    });

    const data = google.visualization.arrayToDataTable(arrayWithData);

    const options = {
      legend: 'none',
      backgroundColor: {
        fill: 'transparent'
      },
      height: 800,
      width: '100%',
      chartArea: {
        height: '75%',
        width: '80%'
      },
      hAxis: {
        textStyle:{color: 'white', bold: true, fontSize: 20}
      },
      vAxis: {
        textStyle:{color: 'white', bold: true, fontSize: 20}
      },
      animation: {
        startup: true,
        duration: 1500,
        easing: 'out',
      },
      annotations: {
        // alwaysOutside: true,
        textStyle: {
            fontSize: 18,
            color: 'white',
            bold: false
        },
        color: 'white'
      },
      isStacked: true,
    };

    const chart = new google.visualization.ColumnChart(document.getElementById('pjesemarrja1900'));
    chart.draw(data, options);
  }
}
