<div class="row" style="width: 80%; margin: auto;">
    <div class="col-sm-3 align-self-center">
        <div _ngcontent-c2="" class="center" id="pie">
            <img _ngcontent-c2="" style="border-radius: 50% !important;" height="230" width="230" [src]="profilePic">
        </div>
        <h2 class="center" style="color: #7D7D7D;">{{ leaderName }}</h2>
    </div>
    <div class="col-sm-5 align-self-center">
        <app-kosovo-map style="width: 70%" [cityVotes]='cityVotes' clickMode='1' [maxValue]='maxValue' ></app-kosovo-map>
        <br><br>
    </div>
    <div class="col-sm-4 align-self-center" id="leader-chart">
        
    </div>
</div>
<!-- <div class="row" style="width: 80%; margin: auto">
    <div class="col-sm-2"></div>
    <div class="col-sm-8">
        <div class="col-sm-12" style="height: 15px; background-image: linear-gradient(to right, #202020, white);"></div>
        <div class="col-sm-12">
            <div class="row">
                <div style="margin-left:20px;"><h4><strong>0</strong></h4></div>
                <div style="margin-left:auto; margin-right:20px;"><h4><strong>{{ maxValue }}</strong></h4></div>
            </div>
        </div>
    </div>
    <br><br>
</div> -->