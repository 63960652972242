<div class="d-flex flex-row lokale-full-screen">
    <div class="col-sm-5 subjektet-fituese-komunat">
        <div class="zgjedhjet-caret">
            <div>
                ZGJEDHJET LOKALE {{ selectedYear }}
                <img class="zgjedhjet-caret-logo" width="100%" src="{{ imgBaseUrl + '/images/zgjedhjet-caret.png' }}"/>
            </div>
        </div>
        <div *ngFor="let party of mostMunicipalitiesCount" class="subjekti">
            <div class="emri">{{party.name}}</div>
            <div class="komunat">
                <div class="komunat-circles">
                    <div class="komunat-pr">
                        <div *ngFor="let c of nrOfCircles(party.municipalities, true, true)" class="komunat-circle"
                            [ngStyle]="{'background-color': party.color}"></div>
                    </div>
                    <div class="komunat-pr">
                        <div *ngFor="let c of nrOfCircles(party.municipalities, true)" class="komunat-circle"
                            [ngStyle]="{'background-color': party.color}"></div>
                    </div>
                </div>
                <div class="komunat-nr" [ngStyle]="{'color': party.color}">{{party.municipalities}}</div>
            </div>
        </div>
    </div>
    <div class="col-sm-5 center-items">
    <div style="width: 100%">
        <app-kosovo-map (cityClickHandeled)="cityClicked($event)" [winingParties]="winingParties" clickMode='-1'></app-kosovo-map>
    </div>
    </div>
    <div class="col-sm-2 subjektet-fituese-ngjyrat">
        <div *ngFor="let party of mostMunicipalitiesCount" class="subjekti">
            <div class="ngjyra" [ngStyle]="{'background-color': party.color}"></div>
            <div class="ngjyra-emri">{{party.name}}</div>
        </div>
    </div>
</div>