<div class="conatiner">
    <div class="row d-flex main-div">
        <div class="d-inline-flex p-2 white-gray-color">
            <h2 class="margin-10"><strong>PARLAMENTARE</strong></h2>
        </div>
        <!-- <div class="d-inline-flex p-2 justify-content-end">
            <p>Log out</p>
        </div> -->
    </div>
    <div class="row menu">
        <div class="col-sm" style="width: 18%;">
            <button 
                type="button"
                [ngClass]="activeComponent === 'statistika-te-pergjithshme' ? 'btn btn-primary active-btn btn-menu' : 'btn btn-primary btn-menu'"
                (click)="changeActiveComponent('statistika-te-pergjithshme')"
            >STATISTIKA&nbsp;TE&nbsp;PERGJITHSHME</button>
        </div>
        <div class="col-sm" style="width: 14%;">
            <button
                type="button"
                [ngClass]="activeComponent === 'gjeografia-zgjedhore' ? 'btn btn-primary active-btn btn-menu' : 'btn btn-primary btn-menu'"
                (click)="changeActiveComponent('gjeografia-zgjedhore')"
            >GJEOGRAFIA&nbsp;ZGJEDHORE</button>
        </div>
        <div class="col-sm" style="width: 8%;">
            <button
                type="button"
                [ngClass]="activeComponent === 'pjesmarreja' ? 'btn btn-primary active-btn btn-menu' : 'btn btn-primary btn-menu'"
                (click)="changeActiveComponent('pjesmarreja')"
            >PJESËMARRJA</button>
        </div>
        <div class="col-sm" style="width: 8%;">
            <button
                type="button"
                [ngClass]="activeComponent === 'koalicionet' ? 'btn btn-primary active-btn btn-menu' : 'btn btn-primary btn-menu'"
                (click)="changeActiveComponent('koalicionet')"
            >KOALICIONET</button>
        </div>
        <div class="col-sm" style="width: 8%;">
            <button
                type="button"
                [ngClass]="activeComponent === 'parlamenti' ? 'btn btn-primary active-btn btn-menu' : 'btn btn-primary btn-menu'"
                (click)="changeActiveComponent('parlamenti')"
            >PARLAMENTI</button>
        </div>
        <div class="col-sm" style="width: 8%;">
            <button
                type="button"
                [ngClass]="activeComponent === 'qeverite' ? 'btn btn-primary active-btn btn-menu' : 'btn btn-primary btn-menu'"
                (click)="changeActiveComponent('qeverite')"
            >QEVERITË</button>
        </div>
        <div class="col-sm" style="width: 8%;">
            <button
                type="button"
                [ngClass]="activeComponent === 'historiku' ? 'btn btn-primary active-btn btn-menu' : 'btn btn-primary btn-menu'"
                (click)="changeActiveComponent('historiku')"
            >HISTORIKU</button>
        </div>
        <div class="col-sm" style="width: 8%;">
            <button
                type="button"
                [ngClass]="activeComponent === 'grafiku' ? 'btn btn-primary active-btn btn-menu' : 'btn btn-primary btn-menu'"
                (click)="changeActiveComponent('grafiku')"
            >GRAFIKAT</button>
        </div>
        <div class="col-sm" style="width: 8%;">
            <button
                type="button"
                [ngClass]="activeComponent === 'krahaso' ? 'btn btn-primary active-btn btn-menu' : 'btn btn-primary btn-menu'"
                (click)="changeActiveComponent('krahaso')"
            >KRAHASO</button>
        </div>
        <div class="col-sm" style="width: 8%;">
            <button 
                type="button"
                [ngClass]="activeComponent === 'parashikimet' ? 'btn btn-primary active-btn btn-menu' : 'btn btn-primary btn-menu'"
                (click)="changeActiveComponent('parashikimet')"
            >PARASHIKIMET</button>
        </div>
    </div>

    <div *ngIf="activeComponent === 'statistika-te-pergjithshme'">
        <app-statistika-te-pergjithshme (goToPjesmarrja)="activeComponent='pjesmarreja'" [electionsYears]="electionsYears"></app-statistika-te-pergjithshme>
    </div>

    <div *ngIf="activeComponent === 'pjesmarreja'">
        <app-pjesemarrja></app-pjesemarrja>
    </div>

    <div *ngIf="activeComponent === 'parashikimet'">
        <app-parashikimet-analistave></app-parashikimet-analistave>
    </div>

    <div *ngIf="activeComponent === 'gjeografia-zgjedhore'">
        <app-gjeografia-zgjedhore [electionsYears]="electionsYears"></app-gjeografia-zgjedhore>
    </div>

    <div *ngIf="activeComponent === 'koalicionet'">
        <app-koalicionet [electionsYears]="electionsYears"></app-koalicionet>
    </div>

    <div *ngIf="activeComponent === 'krahaso'">
        <app-krahaso></app-krahaso>
    </div>

    <div *ngIf="activeComponent === 'parlamenti'">
            <app-parlamenti [electionsYears]="electionsYears"></app-parlamenti>
        </div>

    <div *ngIf="activeComponent === 'qeverite'">
        <app-qeverite [electionsYears]="electionsYears"></app-qeverite>
    </div>

    <div *ngIf="activeComponent === 'historiku'">
        <app-historiku></app-historiku>
    </div>

    <div *ngIf="activeComponent === 'grafiku'">
        <app-grafiku></app-grafiku>
    </div>
</div>