<div id="krahaso" >
    <div class="d-flex flex-row displayContentCenter" style="margin-top: 50px;">
        <div class="w3-container" style="width: 80%;">
            <div class="w3-row">
              <a href="javascript:void(0);" (click)="setCompareType(2)">
                <div class="w3-third tablink w3-bottombar w3-hover-light-grey w3-padding"
                    [ngStyle]="{'border-color': compareType === 2 ? '#e9ca17 ' : '#CBCBCB'}"><b>KRAHASIMI MES SUBJEKTEVE</b></div>
              </a>
              <a href="javascript:void(0);" (click)="setCompareType(1)">
                <div class="w3-third tablink w3-bottombar w3-hover-light-grey w3-padding"
                    [ngStyle]="{'border-color': compareType === 1 ? '#e9ca17 ' : '#CBCBCB'}"><b>KRAHASIMI BRENDA SUBJEKTIT</b></div>
              </a>
              <a href="javascript:void(0);" (click)="setCompareType(3)">
                <div class="w3-third tablink w3-bottombar w3-hover-light-grey w3-padding"
                    [ngStyle]="{'border-color': compareType === 3 ? '#e9ca17 ' : '#CBCBCB'}"><b>KRAHASO KANDIDATET</b></div>
              </a>
            </div>
        </div>

        <!-- <div class="row p-2">
            <div class="col-sm">
                <button
                    type="button"
                    [ngClass]="compareType === 2 ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'"
                    (click)="setCompareType(2)"
                >KRAHASIMI MES SUBJEKTEVE</button>
            </div>
            <div class="col-sm">
                <button
                    type="button"
                    [ngClass]="compareType === 1 ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'"
                    (click)="setCompareType(1)"
                >KRAHASIMI BRENDA SUBJEKTIT</button>
            </div>
            <div class="col-sm">
                <button
                    type="button"
                    [ngClass]="compareType === 3 ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'"
                    (click)="setCompareType(3)"
                >KRAHASO KANDIDATET</button>
            </div>
        </div> -->
    </div>
    <div class="container" style="margin-top: 50px;">
        <div class="col-sm align-self-center">
            <div class="row" *ngIf="compareType != 3">
                <!-- <ng-template ngFor let-count [ngForOf]="createRange(compareType)" let-in="index">
                    <div class="col-sm align-self-center">
                        <select class="form-control" [(ngModel)]="selectedParty[in]">
                            <option *ngFor="let p of partiesList; let i = index" [value]="p.id">{{ p.partyName }}</option>
                        </select>
                    </div>
                </ng-template> -->
                <div class="col-sm align-self-center">
                    <select class="form-control" [(ngModel)]="selectedParty1">
                        <option *ngFor="let p of partiesList; let i = index" [value]="p.id">{{ p.partyName }}</option>
                    </select>
                </div>
                <div class="col-sm align-self-center" *ngIf="compareType === 2">
                    <select class="form-control" [(ngModel)]="selectedParty2">
                        <option *ngFor="let p of partiesList; let i = index" [value]="p.id">{{ p.partyName }}</option>
                    </select>
                </div>
                <div class="col-sm align-self-center">
                    <select class="form-control" [(ngModel)]="selectedYear" (change)="getPartiesToCompare()">
                        <option *ngFor="let y of years" [value]="y">{{ y }}</option>
                    </select>
                </div>
                <div class="col-sm align-self-center" *ngIf="compareType === 1">
                    <select class="form-control" [(ngModel)]="selectedYear1" (change)="getPartiesToCompare()">
                        <option *ngFor="let y of years" [value]="y">{{ y }}</option>
                    </select>
                </div>
                <div class="col-sm align-self-center">
                    <select class="form-control" [(ngModel)]="selectedCity" (change)="getPartiesToCompare()">
                        <option *ngIf="compareType!=3" value="99">Totali</option>
                        <option *ngFor="let c of cities" [value]="c.id">{{ c.name }}</option>
                    </select>
                </div>
                <div class="col-sm align-self-center">
                    <button  class="form-control btn-success" (click)="compareNow()"><strong>KRAHASO</strong></button>
                </div>
            </div>

            <div class="row" *ngIf="compareType == 3">
                <div class="col-sm align-self-center">
                    <select class="form-control" [(ngModel)]="selectedLeader1">
                        <option *ngFor="let l of leadersList1; let i = index" [value]="l.id">{{ l.name }}</option>
                    </select>
                </div>
                <div class="col-sm align-self-center">
                    <select class="form-control" [(ngModel)]="selectedYear" (change)="getLeadersToCompare(true,false)">
                        <option *ngFor="let y of years" [value]="y">{{ y }}</option>
                    </select>
                </div>
                <div class="col-sm align-self-center">
                    <select class="form-control" [(ngModel)]="selectedLeader2">
                        <option *ngFor="let l of leadersList2; let i = index" [value]="l.id">{{ l.name }}</option>
                    </select>
                </div>
                <div class="col-sm align-self-center">
                    <select class="form-control" [(ngModel)]="selectedYear1" (change)="getLeadersToCompare(false,false)">
                        <option *ngFor="let y of years" [value]="y">{{ y }}</option>
                    </select>
                </div>
                <div class="col-sm align-self-center">
                    <button  class="form-control btn-success" (click)="compareNow()"><strong>KRAHASO</strong></button>
                </div>
            </div>


            
            <div *ngIf="compareType == 1" class="row" id="total-votes"></div>
            <div *ngIf="compareType == 1" class="row" id="compare-votes"></div>

            <div *ngIf="compareType == 2" class="row" id="total-votes"></div>
            <div *ngIf="compareType == 2" class="row" id="compare-votes"></div>

            <div *ngIf="compareType == 3" class="row" id="total-votes"></div>
            <div *ngIf="compareType == 3" class="row" id="compare-votes"></div>
        </div>
    </div>
    
</div>
