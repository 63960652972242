<div class="d-flex flex-row lokale-full-screen" *ngIf="selectedCityView === 'Kryetar' && selectedYear !== 2021">
    <div class="col-sm-3 subjektet-fituese-kandidatet">
        <div *ngFor="let candidate of twoCandidates" class="kandidati" style="color: red">
            <div class="emri" [ngStyle]="{'color': candidate.partyColor}">
               <b> {{ candidate.candidate }} </b>
            </div>
            <div class="foto">
                <img height="150px" src="{{ '/images/lokale/' + selectedCityId + '/' + candidate.candidatePhoto }}"/>
            </div>
            <div class="votat" [ngStyle]="{'color': candidate.partyColor}">
               <span style="font-size: xxx-large;"> {{ candidate.percentage }}% </span> <br /> ({{ candidate.nrVotes }})
            </div>
        </div>
    </div>
    <div class="col-sm-6 center-items">
    <div style="width: 100%">
        <object [attr.data]="candidatesSVGUrl" width="100%" > </object>
    </div>
    </div>
    <div class="col-sm-3 subjektet-fituese-ngjyrat">
        <div *ngFor="let candidate of candidates" class="subjekti">
            <div class="kandidati-emri"> {{ candidate.candidate }} </div>
            <div class="ngjyra" [ngStyle]="{'background-color': candidate.partyColor}"></div>
            <div class="ngjyra-emri">{{ candidate.party }}</div>
        </div>
    </div>
</div>

<div class="d-flex flex-row lokale-full-screen" *ngIf="selectedCityView === 'Parlamentare'">
    <div class="col-sm-4 subjektet-fituese-votat">
        <div class="zgjedhjet-caret">
            <div>
                ZGJEDHJET PARLAMENTARE {{ selectedYear }}
                <img class="zgjedhjet-caret-logo" width="100%" src="{{ imgBaseUrl + '/images/zgjedhjet-caret.png' }}"/>
            </div>
        </div>
        <div *ngFor="let party of partiesNumberOfVotes" class="subjekti">
            <div class="emri">{{party.partyName}}</div>
            <div class="votat">
                <div class="votat-pr" [ngStyle]="{'background-color': party.partyColor, 'width': party.percentage}"></div>
                <div class="votat-nr" [ngStyle]="{'left': party.percentage}">{{party.nrOfVotes}}</div>
            </div>
        </div>
    </div>
    <div class="col-sm-6 center-items">
    <div style="width: 100%">
        <object [attr.data]="parlamentSVGUrl" width="100%" > </object>
    </div>
    </div>
    <div class="col-sm-2 subjektet-fituese-ngjyrat">
        <div *ngFor="let party of parlimentResultsByPollingStation" class="subjekti">
            <div class="ngjyra" [ngStyle]="{'background-color': party.partyColor}"></div>
            <div class="ngjyra-emri">{{party.partyName}}</div>
        </div>
    </div>
</div>

<div class="d-flex flex-row lokale-full-screen" *ngIf="selectedCityView === 'Asamble' && selectedYear !== 2021">
    <div class="col-sm-3">
    </div>
    <div class="col-sm-6 center-items">
    <div style="width: 100%">
        <object [attr.data]="assemblySVGUrl" width="100%" > </object>
    </div>
    </div>
    <div class="col-sm-2 subjektet-fituese-ngjyrat">
        <div *ngFor="let party of assemblyResultsByPollingStation" class="subjekti">
            <div class="ngjyra" [ngStyle]="{'background-color': party.partyColor}"></div>
            <div class="ngjyra-emri">{{party.party}}</div>
        </div>
    </div>
</div>