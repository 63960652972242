import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kosovo-map-with-textboxes',
  templateUrl: './kosovo-map-with-textboxes.component.html',
  styleUrls: ['./kosovo-map-with-textboxes.component.css']
})
export class KosovoMapWithTextboxesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
