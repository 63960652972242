import { Component, OnInit } from '@angular/core';

export interface TableModalDialogData {
  partyVotes: any;
}

@Component({
  selector: 'app-table-modal',
  templateUrl: './table-modal.component.html',
  styleUrls: ['./table-modal.component.css']
})
export class TableModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
