import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getBaseUrl } from '../../../../main';

@Injectable({
  providedIn: 'root',
})
export class HistorikuLokaleService {
  baseUrl: string;

  constructor(private http: HttpClient) { 
    this.baseUrl = getBaseUrl();
  }

  getMostVotedPartiesElectiveGeo(year: number) {
    return this.http.get(this.baseUrl + `api/ElectiveGeography/GetMostVotedParties/year/${year}`);
  }

  getCityWiningPartiesByYear(year: number) {
    return this.http.get(this.baseUrl + `api/ElectiveGeography/GetCityWiningPartiesByYear/year/${year}`);
  }

  getPartyVotesByCity(idCity: number) {
    return this.http.get(this.baseUrl + `api/ElectiveGeography/GetPartyVotesByCity/idCity/${idCity}`);
  }

  // Party Votes By City
  savePartyVotesByCity(data) {
    return this.http.post(`${this.baseUrl}api/ElectiveGeography/SavePartyVotesByCity`, JSON.stringify(data));
  }

  editPartyVotesByCity(data) {
    return this.http.put(`${this.baseUrl}api/ElectiveGeography/EditPartyVotesByCity`, JSON.stringify(data));
  }

  deletePartyVotesByCity(id: number) {
    return this.http.delete(`${this.baseUrl}api/ElectiveGeography/DeletePartyVotesByCity/${id}`);
  }

  // Party Votes Elective Geography
  savePartyVotesElectiveGeography(data) {
    return this.http.post(`${this.baseUrl}api/ElectiveGeography/SavePartyVotesElectiveGeography`, JSON.stringify(data));
  }

  editPartyVotesElectiveGeography(data) {
    return this.http.put(`${this.baseUrl}api/ElectiveGeography/EditPartyVotesElectiveGeography`, JSON.stringify(data));
  }

  deletePartyVotesElectiveGeography(id: number) {
    return this.http.delete(`${this.baseUrl}api/ElectiveGeography/DeletePartyVotesElectiveGeography/${id}`);
  }
}
