<!-- <app-kosovo-map></app-kosovo-map> -->

<!-- <div class="d-flex flex-row justify-content-center full-screen">
    <div class="p-2 align-self-center" >
        
    </div>

</div> -->
<div class="d-flex flex-row displayContentCenter">
  <div class="p-2">
    <button type="button" [ngClass]="selectedYear === 2001 ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'" (click)="loadData(2001)">2001</button>
  </div>
  <div class="p-2">
    <button type="button" [ngClass]="selectedYear === 2004 ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'" (click)="loadData(2004)">2004</button>
  </div>
  <div class="p-2">
    <button type="button" [ngClass]="selectedYear === 2007 ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'" (click)="loadData(2007)">2007</button>
  </div>
  <div class="p-2">
    <button type="button" [ngClass]="selectedYear === 2010 ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'" (click)="loadData(2010)">2010</button>
  </div>
  <div class="p-2">
    <button type="button" [ngClass]="selectedYear === 2014 ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'" (click)="loadData(2014)">2014</button>
  </div>
  <div class="p-2">
    <button type="button" [ngClass]="selectedYear === 2017 ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'" (click)="loadData(2017)">2017</button>
  </div>
  <div class="p-2">
    <button type="button" [ngClass]="selectedYear === 2019 ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'" (click)="loadData(2019)">2019</button>
  </div>
  <div class="p-2">
    <button type="button" [ngClass]="selectedYear === 2021 ? 'btn btn-success active-btn' : 'btn btn-success btnYearColor'" (click)="loadData(2021)">2021</button>
  </div>
</div>

<div class="row">
  <div class="col">
    <h1></h1>
  </div>
</div>

<div class="d-flex flex-row full-screen" *ngIf="!showPartyVotes">
  <div class="col-sm-6 " id="subjektet-fituese"></div>
  <div class="col-sm-6">
    <div style="width: 90%; height: auto; margin: auto">
        <app-kosovo-map (cityClickHandeled)="cityClicked($event)" [winingParties]="winingParties" clickMode='-1'></app-kosovo-map>
    </div>
  </div>
</div>

<div *ngIf="showPartyVotes">
  <div class="d-flex flex-row" style="padding-right: 35px">
    <div class="p-2" 
          style="background-color: #D16517; height: 50px; width: 10%; 
                  justify-content: center; align-items: center; display: flex;
                  margin-left: 40px" 
          (click)="showAllContent = true; refreshCharts();">
      <h2 class="white-color" style="margin: 0 0 0 0 !important;">{{ selectedCityName }}</h2>
    </div>
    <div class="p-2 close-modal-div" (click)="closePartyVotes()" 
          style="border-radius: 50% !important; margin-right: 20px; margin-left: auto;">
      <i class="material-icons"> close</i>
    </div>
  </div>
  <div class="d-flex flex-row full-screen" >
      <div class="col-sm-8">
        <div class="row" *ngIf="!showTableModal">
          <div class="col">
            <table class="table" (click)="openDialog(2001)">
              <thead>
                <th class='td-light-b'>SUBJEKTI</th>
                <th class='td-light'>2001</th>
              </thead>
              <tbody>
                <tr *ngFor="let item of partyVotes2001; let i = index">
                  <td [ngClass]="(i % 2 ? 'td-light-b' : 'td-dark-b')">{{ item.partyName }}</td>
                  <td [ngClass]="(i % 2 ? 'td-light' : 'td-dark')">{{ item.partyVotes | number }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col">
            <table class="table" (click)="openDialog(2004)">
              <thead>
                <th class='td-light-b'>SUBJEKTI</th>
                <th class='td-light'>2004</th>
              </thead>
              <tbody>
                <tr *ngFor="let item of partyVotes2004; let i = index">
                  <td [ngClass]="(i % 2 ? 'td-light-b' : 'td-dark-b')">{{ item.partyName }}</td>
                  <td [ngClass]="(i % 2 ? 'td-light' : 'td-dark')">{{ item.partyVotes | number }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col">
            <table class="table" (click)="openDialog(2007)">
              <thead>
                <th class='td-light-b'>SUBJEKTI</th>
                <th class='td-light'>2007</th>
              </thead>
              <tbody>
                <tr *ngFor="let item of partyVotes2007; let i = index">
                  <td [ngClass]="(i % 2 ? 'td-light-b' : 'td-dark-b')">{{ item.partyName }}</td>
                  <td [ngClass]="(i % 2 ? 'td-light' : 'td-dark')">{{ item.partyVotes | number }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row" *ngIf="!showTableModal">
          <div class="col">
            <table class="table" (click)="openDialog(2010)">
              <thead>
                <th class='td-light-b'>SUBJEKTI</th>
                <th class='td-light'>2010</th>
              </thead>
              <tbody>
                <tr *ngFor="let item of partyVotes2010; let i = index">
                  <td [ngClass]="(i % 2 ? 'td-light-b' : 'td-dark-b')">{{ item.partyName }}</td>
                  <td [ngClass]="(i % 2 ? 'td-light' : 'td-dark')">{{ item.partyVotes | number }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col">
            <table class="table" (click)="openDialog(2014)">
              <thead>
                <th class='td-light-b'>SUBJEKTI</th>
                <th class='td-light'>2014</th>
              </thead>
              <tbody>
                <tr *ngFor="let item of partyVotes2014; let i = index">
                  <td [ngClass]="(i % 2 ? 'td-light-b' : 'td-dark-b')">{{ item.partyName }}</td>
                  <td [ngClass]="(i % 2 ? 'td-light' : 'td-dark')">{{ item.partyVotes | number }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col">
            <table class="table" (click)="openDialog(2017)">
              <thead>
                <th class='td-light-b'>SUBJEKTI</th>
                <th class='td-light'>2017</th>
              </thead>
              <tbody>
                <tr *ngFor="let item of partyVotes2017; let i = index">
                  <td [ngClass]="(i % 2 ? 'td-light-b' : 'td-dark-b')">{{ item.partyName }}</td>
                  <td [ngClass]="(i % 2 ? 'td-light' : 'td-dark')">{{ item.partyVotes | number }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row" *ngIf="!showTableModal">
          <div class="col-sm-4">
            <table class="table" (click)="openDialog(2019)">
              <thead>
                <th class='td-light-b'>SUBJEKTI</th>
                <th class='td-light'>2019</th>
              </thead>
              <tbody>
                <tr *ngFor="let item of partyVotes2019; let i = index">
                  <td [ngClass]="(i % 2 ? 'td-light-b' : 'td-dark-b')">{{ item.partyName }}</td>
                  <td [ngClass]="(i % 2 ? 'td-light' : 'td-dark')">{{ item.partyVotes | number }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="showTableModal">
          <div class="d-flex flex-row-reverse">
            <div class="p-2 close-modal-div" (click)="showTableModal = false;" style="border-radius: 50% !important;">
              <i class="material-icons"> close</i>
            </div>
          </div>
          <div class="row centered-div">
            <table class="table" style="height: 75%;">
              <thead>
                <th class='td-light-b'>SUBJEKTI</th>
                <th class='td-light'>{{ tableModalSelectedYear }}</th>
              </thead>
              <tbody>
                <tr *ngFor="let item of tableModalPartyVotes; let i = index">
                  <td [ngClass]="(i % 2 ? 'td-light-b' : 'td-dark-b')">{{ item.partyName }}</td>
                  <td [ngClass]="(i % 2 ? 'td-light' : 'td-dark')">{{ item.partyVotes | number }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div style="padding-left: 70px">
          <app-all-cities-map [cityId]="selectedCityId"></app-all-cities-map>
        </div>
        <!-- <div class="d-flex justify-content-center">
          <div class="p-2">
            <h1 style="color: white"> {{ selectedCityName }} </h1>
          </div>
        </div> -->
      </div>
    </div>
</div>

