<div>
    <div class="w3-container">
        <div class="w3-row">
          <a href="javascript:void(0);" (click)="showParticipationPerTime(1100);">
            <div class="w3-third tablink w3-bottombar w3-hover-light-grey w3-padding"
                [ngStyle]="{'border-color': participationTime === 1100 ? '#e9ca17 ' : '#CBCBCB'}">Deri 11:00</div>
          </a>
          <a href="javascript:void(0);" (click)="showParticipationPerTime(1500);">
            <div class="w3-third tablink w3-bottombar w3-hover-light-grey w3-padding"
                [ngStyle]="{'border-color': participationTime === 1500 ? '#e9ca17 ' : '#CBCBCB'}">Deri 15:00</div>
          </a>
          <a href="javascript:void(0);" (click)="showParticipationPerTime(1900);">
            <div class="w3-third tablink w3-bottombar w3-hover-light-grey w3-padding"
                [ngStyle]="{'border-color': participationTime === 1900 ? '#e9ca17 ' : '#CBCBCB'}">Deri 19:00</div>
          </a>
        </div>
      
        <div class="w3-container city" 
            style="overflow: hidden;"
            [ngStyle]="{'height': participationTime === 1100 ? '800px' : '0px'}">
            <h3>Pjesemarrja deri ne oren 11:00</h3>
            <div id="pjesemarrja1100" class="pjesmarreja" style="margin: auto" ></div>
        </div>
      
        <div class="w3-container city" 
            style="overflow: hidden;"
            [ngStyle]="{'height': participationTime === 1500 ? '800px' : '0px'}">
            <h3>Pjesemarrja deri ne oren 15:00</h3>
            <div id="pjesemarrja1500" class="pjesmarreja" style="margin: auto" ></div>
        </div>
      
        <div class="w3-container city" 
            style="overflow: hidden;"
            [ngStyle]="{'height': participationTime === 1900 ? '800px' : '0px'}">
            <h3>Pjesemarrja deri ne oren 19:00</h3>
            <div id="pjesemarrja1900" class="pjesmarreja" style="margin: auto" ></div>
        </div>
    </div>
</div>
