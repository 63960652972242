import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-all-cities-map',
  templateUrl: './all-cities-map.component.html',
  styleUrls: ['./all-cities-map.component.css']
})
export class AllCitiesMapComponent implements OnInit {

  @Input() cityId: number;
  @Input() cityPartyColor: string = null;
  colorToFill = 'grey';

  constructor() { }

  ngOnInit() {
  }

  colorFill(id: number) {
    if(this.cityPartyColor != null) {
      return this.cityPartyColor;
    }
    return this.static_city_colors[id];
  }

  static_city_colors = [
    "#AEDC3A",
    "#E0B469",
    "#AE498B",
    "#4A6191",
    "#6C93CF",
    "#FF222D",
    "#AEDC3A",
    "#FF8928",
    "#009CE7",
    "#FD8746",
    "#6E91D3",
    "#6495D7",
    "#01AD54",
    "#92E6FF",
    "#0DB7FD",
    "#50AB99",
    "#FF158F",
    "#FE8827",
    "#01AD53",
    "#FE1F56",
    "#92E6FF",
    "#FF1F54",
    "#04B5FE",
    "#009EE9",
    "#5B594A",
    "#3C3C3C",
    "#86726F",
    "#403E3A",
    "#3734A2",
    "#414143",
    "#FD2120",
    "#5EA87B",
    "#3A3997",
    "#00B3F7",
    "#33339B",
    "#443E45",
    "#424143",
  ]
}
