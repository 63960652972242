import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { ParlamentareComponent } from './parlamentare/parlamentare.component';
import { KoalicionetComponent } from './parlamentare/koalicionet/koalicionet.component';
import { PjesemarrjaComponent } from './parlamentare/pjesemarrja/pjesemarrja.component';
import { KosovoMapComponent } from './parlamentare/kosovo-map/kosovo-map.component';
import { StatistikaTePergjithshmeComponent } from './parlamentare/statistika-te-pergjithshme/statistika-te-pergjithshme.component';
import { ParashikimetAnalistaveComponent } from './parlamentare/parashikimet-analistave/parashikimet-analistave.component';
import { GjeografiaZgjedhoreComponent } from './parlamentare/gjeografia-zgjedhore/gjeografia-zgjedhore.component';
import { AllCitiesMapComponent } from './parlamentare/all-cities-map/all-cities-map.component';
import { KosovoMapWithTextboxesComponent } from './parlamentare/kosovo-map-with-textboxes/kosovo-map-with-textboxes.component';
import { TableModalComponent } from './parlamentare/gjeografia-zgjedhore/table-modal/table-modal.component';
import { UlesetNeParlamentComponent } from './parlamentare/koalicionet/uleset-ne-parlament/uleset-ne-parlament.component';
import { KrahasoComponent } from './parlamentare/krahaso/krahaso.component';
import { LideretComponent } from './parlamentare/statistika-te-pergjithshme/lideret/lideret.component';
import { QeveriteComponent } from './parlamentare/qeverite/qeverite.component';
import { ParlamentiComponent } from './parlamentare/parlamenti/parlamenti.component';
import { ParalamentareService } from './parlamentare/paralamentare.service';
import { StatistikaTePergjithshmeService } from './parlamentare/statistika-te-pergjithshme/statistika-te-pergjithshme.service';
import { PjesemarrjaService } from './parlamentare/pjesemarrja/pjesemarrja.service';
import { GjeografiaZgjedhoreService } from './parlamentare/gjeografia-zgjedhore/gjeografia-zgjedhore.service';
import { HistorikuComponent } from './lokale/historiku/historiku.component';
import { HistorikuParlamentareComponent } from './lokale/historiku/paralamentare/parlamentare.component';
import { HistorikuLokaleComponent } from './lokale/historiku/lokale/lokale.component';
import { HistorikuKomunaComponent } from './lokale/historiku/komuna/komuna.component';
import { GrafikuComponent } from './parlamentare/grafiku/grafiku.component';
import { HistorikuAnalizatComponent } from './lokale/historiku/analizat/analizat.component';
// import { AddComponent } from './parlamentare/add/add.component';
// import { ShtoPjesemarrjaComponent } from './parlamentare/add/shto-pjesemarrja/shto-pjesemarrja.component';
// import { ShtoKoalicionetComponent } from './parlamentare/add/shto-koalicionet/shto-koalicionet.component';
// import { ShtoStatistikaTePergjithshmeComponent } from './parlamentare/add/shto-statistika-te-pergjithshme/shto-statistika-t-p.component';
// import { ShtoParashikimetAnalistaveComponent } from './parlamentare/add/shto-parashikimet/shto-parashikimet.component';
// import { ShtoGjeografiaZgjedhoreComponent } from './parlamentare/add/shto-gjeografia-zgjedhore/shto-gj-z.component';
// import { ShtoKrahasoComponent } from './parlamentare/add/shto-krahaso/shto-krahaso.component';
// import { ShtoQeveriteComponent } from './parlamentare/add/shto-qeverite/shto-qeverite.component';
// import { ShtoParlamentiComponent } from './parlamentare/add/shto-parlamenti/shto-parlamenti.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    ParlamentareComponent,
    KoalicionetComponent,
    PjesemarrjaComponent,
    KosovoMapComponent,
    StatistikaTePergjithshmeComponent,
    ParashikimetAnalistaveComponent,
    GjeografiaZgjedhoreComponent,
    AllCitiesMapComponent,
    KosovoMapWithTextboxesComponent,
    TableModalComponent,
    UlesetNeParlamentComponent,
    KrahasoComponent,
    LideretComponent,
    QeveriteComponent,
    ParlamentiComponent,
    // AddComponent,
    // ShtoPjesemarrjaComponent,
    // ShtoKoalicionetComponent,
    // ShtoStatistikaTePergjithshmeComponent,
    // ShtoParashikimetAnalistaveComponent,
    // ShtoGjeografiaZgjedhoreComponent,
    // ShtoKrahasoComponent,
    // ShtoQeveriteComponent,
    // ShtoParlamentiComponent,
    HistorikuComponent,
    HistorikuParlamentareComponent,
    HistorikuLokaleComponent,
    GrafikuComponent,
    HistorikuKomunaComponent,
    HistorikuAnalizatComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    // ApiAuthorizationModule,
    RouterModule.forRoot([
      // { path: '', component: ParlamentareComponent, canActivate: [AuthorizeGuard] },
      { path: '2021', component: HistorikuComponent },
      { path: '', component: ParlamentareComponent },

    ])
  ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
