import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { ParalamentareService } from '../paralamentare.service';
import { imgBaseUrl } from '../parlamentare';

@Component({
  selector: 'app-koalicionet',
  templateUrl: './koalicionet.component.html',
  styleUrls: ['./koalicionet.component.css']
})
export class KoalicionetComponent implements OnInit, OnChanges, OnDestroy {

  @Input() electionsYears: any[] = [];

  selectedYear: string = "2019-Hoti";
  coalitions: string[] = ["2001","2004","2007","2010","2014","2017","2019-Kurti","2019-Hoti"];

  allParties: any[] = [];
  parties: any[] = [];
  partyColors: any[] = [];
  imgBaseUrl = '';
  getTimestamp: number = new Date().getTime();

  constructor(private _parlamentareService: ParalamentareService) { 
    this.imgBaseUrl = imgBaseUrl();
  }

  ngOnInit() {
    this._parlamentareService.getCoalitions().subscribe(response => {
      const res = response as any;
      res.coalitions.forEach(item => {
        const color = res.partyColors.filter(c => c.partyName == item.partyName);
        if (color.length > 0) {
          item.color = color[0].color;
        }

      });
      this.allParties = res.coalitions;
      this.loadData("2019-Hoti");
    });
    // this.loadScript();
  }

  ngOnChanges() {
    if (this.electionsYears.length > 0) {
      // this.selectedYear = this.electionsYears[this.electionsYears.length - 1].year || 2019;
    }
  }

  ngOnDestroy() {
    // this.removeScript();
  }

  loadData(year: string): void {
    // let flourish = document.querySelector(".flourish-embed iframe");
    // if (flourish && flourish['contentWindow'] && flourish['contentWindow'].document.body) {
    //   let element = flourish['contentWindow'].document.body.querySelector('input[value="'+year+'"]');
    //   if (element !== null) {
    //     element.click();
    //   }
    // } else {
    //   return;
    // }

    this.selectedYear = year;
    this.parties = this.allParties.filter(p => p.year === this.selectedYear);
  }

  createRange(number: number) {
    let items: number[] = [];
    for (let i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }

  removeScript() {
    document.getElementById("flourish.embed.js").remove();
  }

  loadScript() {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '/koalicionet/flourish.embed.js?cachebuster='+ new Date().getTime();
    script.id = 'flourish.embed.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
    let ctx = this;
    setTimeout(function() {
      ctx.loadData("2019-Hoti");
    }, 500);
  }
}
