<div class="row top-row custom-row">
    <div class="col-sm"></div>
    <div class="col-sm-10">
        <div class="w3-container" style="width: 100%;">
            <div class="w3-row">
                <ng-template ngFor let-tab [ngForOf]="availableTabs" let-in="index">
                    <a href="javascript:void(0);" (click)="tabClicked(tab)">
                        <div class="w3-quarter tablink w3-bottombar w3-hover-light-grey w3-padding"
                            [ngStyle]="{'border-color': selectedTab === tab ? '#e9ca17 ' : '#CBCBCB'}"><b>{{ tab.replaceAll("_"," ") }}</b></div>
                    </a>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="col-sm"></div>
</div>
<!-- <div class="row top-row" *ngIf="selectedTab !== ''">
    <div class="col-sm-2"></div>
    <div class="col-sm-2">
        <div class="p-2 h-background-color">
            <h4 class="white-color" (click)="selectedTab = ''">
              <i class="material-icons" style="font-size: 18px;" >arrow_back</i>KTHEHU PRAPA </h4>
        </div>
    </div>
</div> -->
<div class="row" *ngFor="let tab of availableTabs; let i = index" [ngStyle]="{'display': selectedTab === tab ? 'block' : 'none'}">
    <div class="col-sm-12">
        <iframe scrolling="no" [src]="tabsSrc[i]" style="width:100%; height:75vh;"></iframe>
        <!-- <div>
            <a href="https://flourish.studio/?utm_source=embed" target='_top'>
                <img alt="Made with Flourish" src="https://public.flourish.studio/resources/made_with_flourish.svg">
            </a>
        </div> -->

        <!-- <div class="flourish-embed" attr.data-src="{{ '/grafiku/' + tab + '/index.html' }}">
        </div> -->
    </div>
</div>
