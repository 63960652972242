import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { HistorikuParlamentareService } from './parlamentare.service';
import { imgBaseUrl } from '../../../parlamentare/parlamentare';

declare let google: any;

@Component({
  selector: 'app-historiku-parlamentare',
  templateUrl: './parlamentare.component.html',
  styleUrls: ['./parlamentare.component.css','../historiku.component.css']
})
export class HistorikuParlamentareComponent implements OnInit, OnChanges {

  @Input() loadYear: number = 2021;
  @Output() cityClickHandeled: EventEmitter<any> = new EventEmitter();

  selectedYear: number = this.loadYear;
  showMap = false;
  mostVotedParties: any[] = [];

  showPartyVotes = false;
  selectedCityId: number;

  showTableModal = false;
  tableModalPartyVotes: any[] = [];
  tableModalSelectedYear: number;

  selectedCityName: string;
  winingParties: any[] = [];
  citiesToIgnore: number[] = [];

  imgBaseUrl = "";

  constructor(private service: HistorikuParlamentareService) {
    this.imgBaseUrl = imgBaseUrl();
    // google.charts.load('current', { 'packages': ['corechart', 'table'] });
    // google.charts.setOnLoadCallback();
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnChanges() {
    if (this.loadYear !== this.selectedYear) {
      this.loadData();
    }
  }

  loadData(): void {
    this.showMap = false;
    this.selectedYear = this.loadYear;
    if (this.selectedYear === 2013) return;
    this.getMostVotedParties();
    // this.getCityWiningPartiesByYear();
  }

  getMostVotedParties(): void {
    this.service.getMostVotedPartiesElectiveGeo(this.selectedYear).subscribe(response => {
      const res = response as any;
      const sorted = res.sort((a, b) => b.nrOfVotes - a.nrOfVotes);
      let sumOfVotes = 0;

      sorted.forEach(p => sumOfVotes += p.nrOfVotes);
      const normalizePercentage = 75 / ((sorted[0].nrOfVotes/sumOfVotes) * 75);
      //75 is the max value for the graph.

      this.mostVotedParties = sorted.map(p => ({
        ...p,
        nrOfVotes: p.nrOfVotes.toLocaleString(),
        percentage: (((p.nrOfVotes/sumOfVotes) * 75) * normalizePercentage) + "%"
      }));

      if (!res["citiesToIgnore"]) {
        res["citiesToIgnore"] = [];
      };
      this.citiesToIgnore = res["citiesToIgnore"];
      this.getCityWiningPartiesByYear();
    });
  }

  getCityWiningPartiesByYear(): void {
    this.service.getCityWiningPartiesByYear(this.selectedYear).subscribe(response => {
      const res = response as any;
      this.winingParties = res;
      this.showMap = true;

      this.citiesToIgnore.forEach(cityId => {
        this.winingParties.push({
          color: "#b1b7bc",
          idCitySvg: cityId,
          partyName: "Komunitetet",
        });
      });
    });
  }

  drawMostVotedParties() {

    if (this.mostVotedParties.length === 0) {
      return;
    }
  }

  cityClicked(data: any) {
    if (this.citiesToIgnore.indexOf(data.cityId) !== -1) return;
    this.cityClickHandeled.emit(data);
  }

  openDialog(year: number): void {
    this.showTableModal = true;
    this.tableModalSelectedYear = year;
    this.tableModalPartyVotes = this[`partyVotes${year}`];
  }

  closePartyVotes(): void {
    this.showPartyVotes = false;
      setTimeout(() => {
        this.drawMostVotedParties();
      }, 1000);
  }
}
